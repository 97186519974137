<template>
  <div>
    <fw-heading size="xl">Mapas</fw-heading>
    <fw-panel title="Mapas internos" boxed="xs" class="my-5">
      <div class="flex gap-3 items-center px-3 py-2">
        <div>
          <fw-icon-report class="w-5 h-5" />
        </div>
        <div class="flex-1 font-medium">Mapa genérico SASUC</div>
        <div>
          <fw-button type="light" @click.native="openExportModal">Gerar mapa</fw-button>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Mapas DGES" :counter="dgesReports ? dgesReports.length : 0" boxed="xs" class="my-5">
      <content-loader v-if="loading" />
      <fw-panel-info v-else-if="!loading && dgesReports.length === 0" clean class="p-2">
        Ainda não foram gerados mapas.
      </fw-panel-info>
      <template v-for="report in dgesReports">
        <div v-if="report && report.files" :key="report.key" class="p-1 border-b border-gray-100">
          <a :href="getCSVFileUrl(report.files[0])" class="flex gap-3 items-center p-3 hover:bg-gray-100 rounded-lg">
            <span>
              <fw-icon-report class="w-5 h-5" />
            </span>
            <span class="flex-1 font-medium">
              <strong>Mapa DGES </strong> {{ report.$index }} de {{ report.created_date | formatDateTime }}
            </span>
            <span class="text-sm opacity-50">
              Período de {{ report.start_date | formatDate }} a {{ report.end_date | formatDate }}
            </span>
          </a>
        </div>
      </template>
    </fw-panel>

    <fw-modal
      v-if="exportMapModal"
      title="Exportar mapa SASUC"
      :active.sync="exportMapModal"
      boxed="sm"
      size="min"
      width="280px"
      @close="closeExportModal()"
    >
      <div class="text-center flex flex-col gap-3">
        <div v-if="false" class="flex gap-3 text-left">
          <b-field :label="'Data de início'">
            <b-datepicker v-model="exportStartDate" placeholder="Início" expanded size="is-small"> </b-datepicker>
          </b-field>
          <b-field :label="'Data de fim'">
            <b-datepicker v-model="exportEndDate" placeholder="Fim" expanded size="is-small"> </b-datepicker>
          </b-field>
        </div>
        <div class="flex flex-col gap-3">
          <fw-button
            :type="canExport ? 'primary' : 'disabled'"
            expanded
            :disabled="!canExport"
            @click.native="downloadMap('sasuc')"
            >Descarregar mapa</fw-button
          >
          <fw-button type="link-muted" @click.native="closeExportModal">Fechar janela</fw-button>
        </div>
      </div>
    </fw-modal>
  </div>
</template>

<script>
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'PanelManageCallReports',
  components: {
    ContentLoader,
  },

  props: {
    call: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      internalReport: null,
      dgesReports: [],
      exportMapModal: false,
      exportStartDate: null,
      exportEndDate: null,
    }
  },

  computed: {
    canExport() {
      return true
      // return this.exportStartDate != null && this.exportEndDate != null && this.exportStartDate <= this.exportEndDate
    },
    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    this.getReports()
  },

  methods: {
    downloadMap(type) {
      if (type == 'sasuc') {
        if (this.internalReport && this.internalReport.filename) {
          let url = this.internalReport.url_format
          url = url.replace('{TOKEN}', this.internalReport.token)
          url = url.replace('{KEY}', this.internalReport.key)
          url = url.replace('{FILENAME}', this.internalReport.filename)
          window.open(url, '_blank')
        }
      }
    },
    openExportModal() {
      this.exportMapModal = true
    },
    closeExportModal() {
      this.exportMapModal = false
    },
    async getReports() {
      this.loading = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getCallReports(this.call.key)
          this.internalReport = response.internal_report
          this.dgesReports = response.reports
        },
        () => {
          setTimeout(() => {
            this.loading = false
          })
        }
      )
    },
    getCSVFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Data de criação",
    "results": "Resultados",
    "noReports": "Sem mapas a mostrar.",
    "all": "Todos",
    "orderBy": {
      "createdDate": "Data de criação"
    },
    "status": "Estado",
    "statusLabel": {
      "submitted": "Submetida",
      "in_analysis": "Em análise",
      "in_analysis_waiting": "Em análise (a aguardar resposta)",
      "complaint": "Audiência de interessados",
      "complaint_analysis": "Audiência de interessados (em análise)",
      "waiting_rejection": "Em análise (sem resposta)",
      "complaint_waiting_rejection": "Audiência de interessados (sem resposta)",
      "in_progress": "Em progresso",
      "closed": "Terminada"
    }
  },
  "en": {
    "createdDate": "Creation date",
    "results": "Results",
    "noReports": "No reports to present.",
    "all": "All",
    "orderBy": {
      "createdDate": "Creation date"
    },
    "status": "Status",
    "statusLabel": {
      "submitted": "Submitted",
      "in_analysis": "In analysis",
      "in_analysis_waiting": "In analysis - waiting",
      "complaint": "Complaint",
      "complaint_analysis": "Complaint analysis",
      "in_progress": "In progress",
      "closed": "Closed"
    }
  }
}
</i18n>
