<template>
  <div class="w-full h-full flex flex-col">
    <div class="px-5 py-4 flex flex-col">
      <fw-heading size="h3" muted>
        <div class="flex gap-1 items-center">
          <div class="flex-1">
            Candidaturas
            <fw-badge v-if="applications" inline type="simple">{{ applications.length }}</fw-badge>
          </div>
          <div>
            <fw-button type="xlight" class="flex gap-0.5 items-center" @click.native="goback()">
              <fw-icon-chevron-left class="w-5 h-5 text-gray-500 opacity-80 -ml-2" />
              <fw-icon-search class="w-5 h-5 text-gray-500" />
            </fw-button>
          </div>
        </div>
      </fw-heading>
    </div>
    <div v-if="applications" class="flex flex-1 flex-col gap-2 overflow-y-auto">
      <RecycleScroller
        ref="sidebarApplicationsList"
        v-slot="{ item, index }"
        :items="applications"
        :item-size="50"
        :buffer="25"
        key-field="key"
        class="px-2"
      >
        <div class="px-2">
          <button
            class="flex px-3 py-2 gap-3 my-1 relative w-full text-left group rounded-lg text-sm"
            :class="{
              'text-primary bg-white shadow-md': item.key == activeApplication,
              'bg-gray-300 bg-opacity-20 hover:text-gray-800': item.key !== activeApplication,
            }"
            @click="loadApplication(item)"
          >
            <div>
              <v-clamp v-if="users[item.user_key]" class="font-semibold" autoresize :max-lines="1"
                >{{ users[item.user_key].name }}
              </v-clamp>
              <div v-else>Sem nome</div>
              <fw-dot :color="item.has_updates ? 'primary' : 'light'" :ping="item.has_updates" class="m-1" />
              <div class="text-xs opacity-80">Atualizada em {{ item.updated_date | formatDateTime }}</div>
            </div>
          </button>
        </div>
      </RecycleScroller>
    </div>
    <BlockPagination
      v-if="showPagination"
      :per-page="limit"
      :total="totalItems"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="$emit('page-changed', $event)"
    />
  </div>
</template>
<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
export default {
  name: 'SidebarApplicationsList',
  components: {
    RecycleScroller,
    BlockPagination,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
    activeApplication: {
      type: String,
      default: '',
    },
    users: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    /*results: {
      type: Object,
      default: () => {},
    },*/
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limit: 25,
    }
  },
  computed: {
    page() {
      return this.pagination && this.pagination.current_page ? this.pagination.current_page : 1
    },
    showPagination() {
      return this.pagination && this.pagination.total_pages && this.pagination.total_pages > 1 && !this.loading
    },
    totalItems() {
      return this.pagination?.total_items && this.pagination.total_items ? this.pagination.total_items : 0
    },
    totalPages() {
      return this.pagination && this.pagination.total_pages ? this.pagination.total_pages : 0
    },
  },
  mounted() {
    //scroll to active application
    if (this.activeApplication) {
      const index = this.applications.findIndex(application => application.key == this.activeApplication)
      //this.$refs.sidebarApplicationsList.scrollToIndex(index)
      this.$refs.sidebarApplicationsList.scrollToItem(index)
    }
  },
  methods: {
    loadApplication(application) {
      this.$router.push({
        name: 'manage-call-application',
        params: { key: this.$route.params.key, applicationKey: application.key },
      })
    },
    goback() {
      this.$router.push({ name: `manage-call-applications`, params: { key: this.$route.params.key } })
    },
  },
}
</script>
