<template>
  <PanelNotifications
    api-endpoint="getCallNotifications"
    :active-filters="activeFilters"
    :inject-payload="{ callKey: callKey }"
    :show-export-toolbar="false"
  />
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  components: {
    PanelNotifications,
  },

  props: {
    callKey: {
      type: String,
    },
  },

  data() {
    return {
      activeFilters: {
        call: this.callKey,
      },
    }
  },
}
</script>
