<template>
  <div>
    <div class="overflow-auto" style="max-height: 500px">
      <fw-panel id="form_section_personal_data" :title="$t('personalData')" class="my-5 px-2" custom-class="bg-white">
        <div>
          <fw-label>{{ $t('fullName') }}</fw-label>
          <TextInput
            v-model="tmpData.name"
            :minlength="3"
            :maxlength="250"
            autocomplete="new-password"
            :class="{
              error: $v.tmpData.name.$error,
            }"
          />
          <fw-tip v-if="$v.tmpData.name.$error" error>
            <span v-if="!$v.tmpData.name.required">{{ $t('errors.required.name') }}</span>
            <span v-else>{{ $t('errors.invalid.name') }}</span>
          </fw-tip>
        </div>

        <div class="mt-4 flex gap-4">
          <div>
            <fw-label>{{ $t('phoneCountry') }}</fw-label>
            <PhoneCountriesSelect
              :placeholder="$t('phoneCountry')"
              :input="tmpData.phone_country_code"
              @update="phoneCountryChanged($event)"
            />
          </div>
          <div>
            <fw-label>{{ $t('phone') }}</fw-label>
            <NumberInput
              v-model="tmpData.phone_number"
              :placeholder="$t('phone')"
              :maxlength="20"
              :class="{
                error: $v.tmpData.phone_number.$error,
              }"
            ></NumberInput>
            <fw-tip v-if="$v.tmpData.phone_number.$error" error>
              <span v-if="!$v.tmpData.phone_number.required">{{ $t('errors.required.phoneNumber') }}</span>
              <span v-else>{{ $t('errors.invalid.phoneNumber') }}</span>
            </fw-tip>
          </div>
        </div>

        <fw-heading size="h4" class="mt-10">{{ $t('id.label') }}</fw-heading>
        <div class="flex mt-2 gap-4">
          <div>
            <fw-label>{{ $t('id.type.label') }}</fw-label>
            <b-select v-model="tmpData.identity_type" class="fw-select w-full" :placeholder="$t('id.type.placeholder')">
              <option value="cc">{{ $t('id.type.cc') }}</option>
              <option value="passport">{{ $t('id.type.passport') }}</option>
              <option value="residence_permit">{{ $t('id.type.residence_permit') }}</option>
            </b-select>
            <fw-tip v-if="$v.tmpData.identity_type.$error" error>
              {{ $t('errors.required.identityType') }}
            </fw-tip>
          </div>
          <div>
            <fw-label>{{ $t('id.number') }}</fw-label>
            <TextInput
              v-model="tmpData.identity_value"
              :placeholder="tmpData.identity_type == 'cc' ? 'DDDDDDDDCAAT' : 'XXXXXXXXX'"
              :class="{
                error: $v.tmpData.identity_value.$error,
              }"
              :minlength="3"
              :maxlength="50"
            ></TextInput>
            <fw-tip v-if="$v.tmpData.identity_value.$error" error>
              <span v-if="!$v.tmpData.identity_value.required">{{ $t('errors.required.identityCode') }}</span>
              <span v-else>{{ $t('errors.invalid.identityCode') }}</span>
            </fw-tip>
          </div>
          <div>
            <fw-label>{{ $t('id.validDate.label') }}</fw-label>
            <b-datepicker
              v-model="tmpData.identity_expire_date"
              :min-date="minDate"
              :years-range="[-10, 50]"
              locale="pt-PT"
              :placeholder="$t('id.validDate.placeholder')"
            >
            </b-datepicker>
            <fw-tip v-if="$v.tmpData.identity_expire_date.$error" error>
              <span v-if="!$v.tmpData.identity_expire_date.required">{{
                $t('errors.required.identityExpireDate')
              }}</span>
              <span v-else>{{ $t('errors.invalid.identityExpireDate') }}</span>
            </fw-tip>
          </div>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('vat') }}</fw-label>
          <div>
            <NumberInput
              v-model="tmpData.vat_value"
              :maxlength="9"
              :placeholder="$t('vat')"
              :class="{
                error: $v.tmpData.vat_value.$error,
              }"
              class="inline-block"
            ></NumberInput>
          </div>
          <fw-tip v-if="$v.tmpData.vat_value.$error" error>
            <span v-if="!$v.tmpData.vat_value.required">{{ $t('errors.required.vat') }}</span>
            <span v-else>{{ $t('errors.invalid.vat') }}</span>
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>IBAN (português)</fw-label>
          <div class="flex gap-0.5 items-center bg-gray-100 rounded-lg">
            <div class="pl-5 text-gray-600">PT50</div>
            <div class="flex-1">
              <TextInput
                v-model="tmpData.iban"
                placeholder="XXXXXXXXXXXXXXXXXXXXX"
                :class="{
                  error: $v.tmpData.iban.$error,
                }"
                :minlength="21"
                :maxlength="21"
              ></TextInput>
            </div>
          </div>

          <fw-tip v-if="$v.tmpData.iban.$error" error>
            <span v-if="!$v.tmpData.iban.required">{{ $t('errors.required.iban') }}</span>
            <span v-else>{{ $t('errors.invalid.iban') }}</span>
          </fw-tip>

          <div class="my-2">
            <fw-label>{{ $t('isAccountHolder') }}</fw-label>
            <div>
              <OnoffOption
                :off-as-gray="false"
                :options="[$t('no'), $t('yes')]"
                :selected-option="
                  [false, 0].includes(tmpData.is_account_holder)
                    ? $t('no')
                    : [true, 1].includes(tmpData.is_account_holder)
                    ? $t('yes')
                    : null
                "
                @input="applicantIsAccountHolderChanged"
              />
            </div>
          </div>

          <div v-if="tmpData.is_account_holder == false">
            <fw-label>{{ $t('accountHolder') }}</fw-label>
            <TextInput
              v-model="tmpData.account_holder"
              :minlength="3"
              :maxlength="250"
              autocomplete="new-password"
              :class="{
                error: $v.tmpData.account_holder.$error,
              }"
            ></TextInput>
            <fw-tip v-if="$v.tmpData.account_holder.$error" error>
              <span v-if="!$v.tmpData.account_holder.required">{{ $t('errors.required.accountHolder') }}</span>
              <span v-else>{{ $t('errors.invalid.accountHolder') }}</span>
            </fw-tip>
          </div>
        </div>

        <fw-heading size="h4" class="mt-10">Residência do agregado familiar</fw-heading>
        <div class="mt-5">
          <fw-label>{{ $t('address') }}</fw-label>
          <TextInput
            v-model="tmpData.address"
            :minlength="3"
            :maxlength="250"
            :placeholder="$t('address')"
            :class="{
              error: $v.tmpData.address.$error,
            }"
          ></TextInput>

          <fw-tip v-if="$v.tmpData.address.$error" error>
            <span v-if="!$v.tmpData.address.required">{{ $t('errors.required.address') }}</span>
            <span v-else>{{ $t('errors.invalid.address') }}</span>
          </fw-tip>
        </div>

        <div class="mt-4 flex gap-4">
          <div>
            <fw-label>{{ $t('postalCode') }}</fw-label>
            <TextInput
              v-model="tmpData.postal_code"
              placeholder="XXXX-XXX"
              :maxlength="8"
              :class="{
                error: $v.tmpData.postal_code.$error,
              }"
            ></TextInput>
            <fw-tip v-if="$v.tmpData.postal_code.$error" error>
              <span v-if="!$v.tmpData.postal_code.required">{{ $t('errors.required.postalCode') }}</span>
              <span v-else>{{ $t('errors.invalid.postalCode') }}</span>
            </fw-tip>
          </div>
          <div>
            <fw-label>{{ $t('locality') }}</fw-label>
            <TextInput
              v-model="tmpData.locality"
              :placeholder="$t('locality')"
              :maxlength="250"
              :class="{
                error: $v.tmpData.locality.$error,
              }"
            ></TextInput>
            <fw-tip v-if="$v.tmpData.locality.$error" error>
              <span v-if="!$v.tmpData.locality.required">{{ $t('errors.required.locality') }}</span>
              <span v-else>{{ $t('errors.invalid.locality') }}</span>
            </fw-tip>
          </div>
        </div>
        <div class="mt-5">
          <fw-label>{{ $t('country') }}</fw-label>
          <CountriesSelect
            :placeholder="$t('country')"
            :input="tmpData.country_code"
            @update="countryChanged($event)"
          />
          <fw-tip v-if="$v.tmpData.country_code.$error" error>
            <span v-if="!$v.tmpData.country_code.required">{{ $t('errors.required.country') }}</span>
            <span v-else>{{ $t('errors.invalid.country') }}</span>
          </fw-tip>
        </div>

        <div>
          <fw-heading size="h4" class="mt-10">{{ $t('schoolresidency') }}</fw-heading>
          <div class="mt-5">
            <fw-label>{{ $t('address') }}</fw-label>
            <TextInput
              v-model="tmpData.address_during_classes.address"
              :minlength="3"
              :maxlength="250"
              :placeholder="$t('address')"
              :class="{
                error: $v.tmpData.address_during_classes.address.$error,
              }"
            ></TextInput>
            <fw-tip v-if="$v.tmpData.address_during_classes.address.$error" error>
              <span v-if="!$v.tmpData.address_during_classes.address.required">{{
                $t('errors.required.address')
              }}</span>
              <span v-else>{{ $t('errors.invalid.address') }}</span>
            </fw-tip>
          </div>

          <div class="mt-4 flex gap-4">
            <div>
              <fw-label>{{ $t('postalCode') }}</fw-label>
              <TextInput
                v-model="tmpData.address_during_classes.postal_code"
                placeholder="XXXX-XXX"
                :maxlength="8"
                :class="{
                  error: $v.tmpData.address_during_classes.postal_code.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.tmpData.address_during_classes.postal_code.$error" error>
                <span v-if="!$v.tmpData.address_during_classes.postal_code.required">{{
                  $t('errors.required.postalCode')
                }}</span>
                <span v-else>{{ $t('errors.invalid.postalCode') }}</span>
              </fw-tip>
            </div>
            <div>
              <fw-label>{{ $t('locality') }}</fw-label>
              <TextInput
                v-model="tmpData.address_during_classes.locality"
                :placeholder="$t('locality')"
                :maxlength="250"
                :class="{
                  error: $v.tmpData.address_during_classes.locality.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.tmpData.address_during_classes.locality.$error" error>
                <span v-if="!$v.tmpData.address_during_classes.locality.required">{{
                  $t('errors.required.locality')
                }}</span>
                <span v-else>{{ $t('errors.invalid.locality') }}</span>
              </fw-tip>
            </div>
          </div>
          <div class="mt-5">
            <fw-label>{{ $t('country') }}</fw-label>
            <CountriesSelect
              :placeholder="$t('country')"
              :input="tmpData.address_during_classes.country_code"
              @update="countryChanged($event, 'address_during_classes')"
            />
            <fw-tip v-if="$v.tmpData.address_during_classes.country_code.$error" error>
              <span v-if="!$v.tmpData.address_during_classes.country_code.required">{{
                $t('errors.required.country_code')
              }}</span>
              <span v-else>{{ $t('errors.invalid.country_code') }}</span>
            </fw-tip>
          </div>
        </div>
      </fw-panel>

      <fw-panel
        v-if="tmpData != null"
        id="form_section_academic_info"
        :title="$t('academicInfo.title')"
        class="my-5 px-2"
        custom-class="bg-white"
      >
        <div>
          <fw-label>{{ $t('academicInfo.studentNumber.label') }}</fw-label>
          <TextInput
            v-model="tmpData.academic_info.student_number"
            :disabled="true"
            :maxlength="12"
            :placeholder="$t('academicInfo.studentNumber.placeholder')"
            class="w-36"
            :class="{
              error: $v.tmpData.academic_info.student_number.$error,
            }"
          ></TextInput>
          <fw-tip v-if="$v.tmpData.academic_info.student_number.$error" error>
            <span v-if="!$v.tmpData.academic_info.student_number.required">{{
              $t('errors.required.studentNumber')
            }}</span>
            <span v-else>{{ $t('errors.invalid.studentNumber') }}</span>
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('academicInfo.course.label') }}</fw-label>
          <TextInput
            v-model="tmpData.academic_info.course"
            :maxlength="250"
            :placeholder="$t('academicInfo.course.placeholder')"
            :class="{
              error: $v.tmpData.academic_info.course.$error,
            }"
          ></TextInput>
          <fw-tip v-if="$v.tmpData.academic_info.course.$error" error>
            <span v-if="!$v.tmpData.academic_info.course.required">{{ $t('errors.required.course') }}</span>
            <span v-else>{{ $t('errors.invalid.course') }}</span>
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('academicInfo.studyCycle.label') }}</fw-label>
          <b-select
            v-model="tmpData.academic_info.study_cycle"
            class="fw-select w-full"
            :class="{
              error: $v.tmpData.academic_info.study_cycle.$error,
            }"
          >
            <option value="1st">1º Ciclo - Licenciatura</option>
            <option value="2nd">2º Ciclo - Mestrado</option>
            <option value="1st-2nd">1º e 2º Ciclo - Mestrado Integrado</option>
            <option value="3rd">3º Ciclo - Doutoramento</option>
          </b-select>
          <fw-tip v-if="$v.tmpData.academic_info.study_cycle.$error" error>
            <span v-if="!$v.tmpData.academic_info.study_cycle.required">{{ $t('errors.required.studyCycle') }}</span>
            <span v-else>{{ $t('errors.invalid.studyCycle') }}</span>
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('academicInfo.institution.label') }}</fw-label>
          <b-select
            v-model="tmpData.academic_info.institution"
            class="fw-select w-full"
            :class="{
              error: $v.tmpData.academic_info.institution.$error,
            }"
          >
            <option value="fluc">Faculdade de Letras</option>
            <option value="fduc">Faculdade de Direito</option>
            <option value="fmuc">Faculdade de Medicina</option>
            <option value="fctuc">Faculdade de Ciências e Tecnologia</option>
            <option value="ffuc">Faculdade de Farmácia</option>
            <option value="feuc">Faculdade de Economia</option>
            <option value="fpce">Faculdade de Psicologia e de Ciências da Educação</option>
            <option value="fcdef">Faculdade de Ciências do Desporto e Educação Física</option>
            <option value="ca">Colégio das Artes</option>
            <option value="iii">Instituto de Investigação Interdisciplinar</option>
          </b-select>
          <fw-tip v-if="$v.tmpData.academic_info.institution.$error" error>
            <span v-if="!$v.tmpData.academic_info.institution.required">{{ $t('errors.required.institution') }}</span>
            <span v-else>{{ $t('errors.invalid.institution') }}</span>
          </fw-tip>
        </div>
      </fw-panel>

      <fw-panel id="form_section_files" :title="$t('files.title')" class="my-5 px-2" custom-class="bg-white">
        <div>
          <fw-label>{{ $t('files.householdFiscalAddress.title') }}</fw-label>
          <div v-if="files.household_fiscal_address && files.household_fiscal_address.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="file in files.household_fiscal_address"
              :key="file.key"
              :allow-classified="false"
              :file="file"
              @save="saveFile(file, 'household_fiscal_address')"
              @remove="removeFile(file, 'household_fiscal_address')"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            v-if="checks.canEdit"
            :label="$t('uploadFiles')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_household_fiscal_address"
            allowed="all"
            :clear-after="true"
            input-id="upload_input_household_fiscal_address"
            :files.sync="householdFiscalAddressFiles"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code="household_fiscal_address"
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded($event, 'household_fiscal_address')"
          />
          <fw-tip v-if="$v.files.household_fiscal_address.$error" error>
            {{ $t('errors.required.files') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('files.familyAllowance.title') }}</fw-label>
          <div v-if="files.family_allowance && files.family_allowance.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="file in files.family_allowance"
              :key="file.key"
              :can-edit="false"
              :can-remove="checks.canEdit"
              :allow-classified="false"
              :file="file"
              @save="saveFile(file, 'family_allowance')"
              @remove="removeFile(file, 'family_allowance')"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            v-if="checks.canEdit"
            :label="$t('uploadFiles')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_family_allowance"
            allowed="all"
            :clear-after="true"
            input-id="upload_input_family_allowance"
            :files.sync="familyAllowanceFiles"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code="family_allowance"
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded($event, 'family_allowance')"
          />
          <fw-tip v-if="$v.files.family_allowance.$error" error>
            {{ $t('errors.required.files') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('files.permanentResidence.title') }}</fw-label>
          <div v-if="files.permanent_residence && files.permanent_residence.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="file in files.permanent_residence"
              :key="file.key"
              :can-edit="false"
              :can-remove="checks.canEdit"
              :allow-classified="false"
              :file="file"
              @save="saveFile(file, 'permanent_residence')"
              @remove="removeFile(file, 'permanent_residence')"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            v-if="checks.canEdit"
            :label="$t('uploadFiles')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_permanent_residence"
            allowed="all"
            :clear-after="true"
            input-id="upload_input_permanent_residence"
            :files.sync="permanentResidenceFiles"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code="permanent_residence"
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded($event, 'permanent_residence')"
          />
          <fw-tip v-if="$v.files.permanent_residence.$error" error>
            {{ $t('errors.required.files') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('files.nonExistenceOfDebts.title') }}</fw-label>
          <div v-if="files.non_existence_of_debts && files.non_existence_of_debts.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="file in files.non_existence_of_debts"
              :key="file.key"
              :can-edit="false"
              :can-remove="checks.canEdit"
              :allow-classified="false"
              :file="file"
              @save="saveFile(file, 'non_existence_of_debts')"
              @remove="removeFile(file, 'non_existence_of_debts')"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            v-if="checks.canEdit"
            :label="$t('uploadFiles')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_non_existence_of_debts"
            allowed="all"
            :clear-after="true"
            input-id="upload_input_non_existence_of_debts"
            :files.sync="nonExistenceOfDebtsFiles"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code="non_existence_of_debts"
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded($event, 'non_existence_of_debts')"
          />
          <fw-tip v-if="$v.files.non_existence_of_debts.$error" error>
            {{ $t('errors.required.files') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('files.paymentReceipts.title') }}</fw-label>
          <div v-if="files.payment_receipts && files.payment_receipts.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="file in files.payment_receipts"
              :key="file.key"
              :can-edit="false"
              :can-remove="checks.canEdit"
              :allow-classified="false"
              :file="file"
              @save="saveFile(file, 'payment_receipts')"
              @remove="removeFile(file, 'payment_receipts')"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            v-if="checks.canEdit"
            :label="$t('uploadFiles')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_payment_receipts"
            allowed="all"
            :clear-after="true"
            input-id="upload_input_payment_receipts"
            :files.sync="paymentReceiptsFiles"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code="payment_receipts"
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded($event, 'payment_receipts')"
          />
          <fw-tip v-if="$v.files.payment_receipts.$error" error>
            {{ $t('errors.required.files') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('files.iban.title') }}</fw-label>
          <div v-if="files.iban && files.iban.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="file in files.iban"
              :key="file.key"
              :can-edit="false"
              :can-remove="checks.canEdit"
              :allow-classified="false"
              :file="file"
              @save="saveFile(file, 'iban')"
              @remove="removeFile(file, 'iban')"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            v-if="checks.canEdit"
            :label="$t('uploadFiles')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_iban"
            allowed="all"
            :clear-after="true"
            input-id="upload_input_iban"
            :files.sync="ibanFiles"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code="iban"
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded($event, 'iban')"
          />
          <fw-tip v-if="$v.files.iban.$error" error>
            {{ $t('errors.required.files') }}
          </fw-tip>
        </div>
      </fw-panel>
    </div>
    <div class="flex justify-end gap-3 pt-5">
      <div>
        <fw-button @click.native="$emit('close')">{{ $t('close') }}</fw-button>
      </div>
      <div>
        <fw-button type="primary" :disabled="saving" :loading="saving" @click.native="saveApplication">{{
          $t('save')
        }}</fw-button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import CountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/CountriesSelect'
import PhoneCountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/PhoneCountriesSelect'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { required, maxLength, minLength, integer } from 'vuelidate/lib/validators'
import { postalCode, vat, validCC } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'

export default {
  components: {
    TextInput,
    NumberInput,
    CountriesSelect,
    PhoneCountriesSelect,
    OnoffOption,
    Uploader,
    RecordFileEntry,
  },

  props: {
    application: {
      type: Object,
      default: () => {
        return {}
      },
    },
    call: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  validations() {
    return {
      tmpData: {
        // Personal data
        name: { required, min: minLength(3), max: maxLength(255) },
        address: { required, min: minLength(3) },
        postal_code: { required, validPostalCode: postalCode(true) },
        locality: { required, min: minLength(3), max: maxLength(255) },
        country_code: { required },
        phone_number: { required, min: minLength(3), max: maxLength(255) },
        identity_type: { required },
        identity_value: { required, min: minLength(3), max: maxLength(50), validCC },
        identity_expire_date: { required },
        vat_value: { required, vat },
        vat_country_code: { required },
        iban: { required, integer, min: minLength(21), max: maxLength(21) },
        account_holder: {
          required: value => [true, 1].includes(this.tmpData.is_account_holder) || Boolean(value),
        },
        address_during_classes: {
          address: {
            required,
            min: minLength(3),
          },
          postal_code: {
            required,
            validPostalCode: postalCode(true),
          },
          locality: {
            required,
            min: minLength(3),
            max: maxLength(255),
          },
          country_code: {
            required,
          },
        },
        academic_info: {
          student_number: { required, min: minLength(5) },
          course: {
            required,
            min: minLength(1),
            max: maxLength(255),
          },
          institution: {
            required,
            min: minLength(1),
            max: maxLength(255),
          },
          study_cycle: {
            required,
            min: minLength(1),
            max: maxLength(255),
          },
        },

        // Acceptance
        accept_extraordinary_support_for_housing: { checked: value => value === true },
        accept_data_treatment: { checked: value => value === true },
        entered_required_docs: { checked: value => value === true },
      },

      files: {
        household_fiscal_address: { required, min: minLength(1) },
        family_allowance: { required, min: minLength(1) },
        permanent_residence: { required, min: minLength(1) },
        non_existence_of_debts: { required, min: minLength(1) },
        payment_receipts: { required, min: minLength(1) },
        iban: { required, min: minLength(1) },
      },
    }
  },

  data() {
    return {
      saving: false,
      loading: false,
      minDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      tmpData: null,
      files: {
        household_fiscal_address: [],
        family_allowance: [],
        permanent_residence: [],
        non_existence_of_debts: [],
        payment_receipts: [],
        iban: [],
      },
      checks: {
        canEdit: true,
      },
      familyAllowanceFiles: [],
      householdFiscalAddressFiles: [],
      ibanFiles: [],
      permanentResidenceFiles: [],
      nonExistenceOfDebtsFiles: [],
      paymentReceiptsFiles: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  watch: {
    '$v.tmpData.$error'(hasError) {
      this.$emit('form-with-errors', hasError)
    },
  },

  created() {
    let applicationCopy = JSON.parse(JSON.stringify(this.application.data))
    //parse dates and others

    //this.files = JSON.parse(JSON.stringify(this.application.files))

    applicationCopy.country_code = applicationCopy.country_code || 'PT'

    applicationCopy.phone_country_code = applicationCopy.phone_country_code || 'PT'
    if (applicationCopy.phone_number) {
      applicationCopy.phone_number = Number(applicationCopy.phone_number)
    }

    // Vat number (convert to number)
    applicationCopy.vat_value = applicationCopy.vat_value ? Number(applicationCopy.vat_value) : null

    // Account holder (convert to boolean)
    applicationCopy.is_account_holder = Boolean(applicationCopy.is_account_holder)

    // Address during_classes
    if (applicationCopy.address_during_classes) {
      applicationCopy.address_during_classes.country_code = applicationCopy.address_during_classes.country_code || 'PT'
      applicationCopy.address_during_classes.phone_country_code =
        applicationCopy.address_during_classes.phone_country_code || 'PT'
      if (applicationCopy.address_during_classes.phone_number) {
        applicationCopy.address_during_classes.phone_number = Number(
          applicationCopy.address_during_classes.phone_number
        )
      }
    }

    // Parse IBAN (just in case)
    if (applicationCopy.iban && applicationCopy.iban.startsWith('PT50')) {
      applicationCopy.iban = applicationCopy.iban.replace('PT50', '')
    }

    applicationCopy.identity_expire_date = applicationCopy.identity_expire_date
      ? Dates.build(applicationCopy.identity_expire_date).toDate()
      : null

    // Force data

    if (!applicationCopy.address_during_classes) {
      applicationCopy.address_during_classes = {
        address: null,
        postal_code: null,
        locality: null,
        country_code: 'PT',
        phone_number: null,
        phone_country_code: 'PT',
      }
    }

    if (!applicationCopy.academic_info) {
      applicationCopy.academic_info = {
        student_number: null,
        course: null,
        institution: null,
        study_cycle: null,
      }
    }

    // Number
    if (this.user.number) {
      applicationCopy.academic_info.student_number = this.user.number
    }

    // VAT country (PT)
    applicationCopy.vat_country_code = 'PT'

    this.tmpData = applicationCopy
    this.setFiles(this.application.files)
  },

  methods: {
    setFiles(files) {
      console.log('processing', files)
      let sectionMap = {
        household_fiscal_address: 'householdFiscalAddressFiles',
        family_allowance: 'familyAllowanceFiles',
        permanent_residence: 'permanentResidenceFiles',
        non_existence_of_debts: 'nonExistenceOfDebtsFiles',
        payment_receipts: 'paymentReceiptsFiles',
        iban: 'ibanFiles',
      }
      files.forEach(file => {
        console.log('processing ', file, ' => ', sectionMap[file.section])
        this.files[file.section].push(file)
      })
    },

    async removeFile(file, fileType) {
      const fileKey = file.key
      try {
        const response = await this.api.deleteApplicationFile(this.call.key, this.application.key, [fileKey])
        console.log('removeFile --> ', file.key, response)
        this.files[fileType] = this.files[fileType].filter(el => el.key != file.key)
      } catch (error) {
        console.error(error)
      }
    },

    saveFile(file, fileType) {
      console.log('save filename', file, fileType)
      let currentFile = this.files[fileType].find(el => el.key == file.key)
      if (currentFile) currentFile.filename = file.title //this.$set(this.application.files, fileType, file)

      console.log('this.application.files[fileType] :>> ', this.files[fileType])
      console.log('current :>> ', currentFile)
    },

    downloadFile(file) {
      this.$emit('download-file', file)
    },
    async uploaded(files, fileType) {
      console.log('uploaded files', files, fileType)
      const uploadedFiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            console.log('file :>> ', file.response.data.file)
            uploadedFiles.push({
              key: file.response.data.file.key,
              filename: file.response.data.file.filename,
              section: fileType,
            })
          }
        }
      }

      if (!this.files[fileType]) {
        this.$set(this.files, fileType, uploadedFiles)
      } else {
        this.$set(this.files, fileType, this.files[fileType].concat(uploadedFiles))
      }
      console.log('this.application.files :>> ', this.files)
    },

    countryChanged(country, objectkey = null) {
      console.log('countryChanged', country, objectkey)
      if (objectkey) {
        this.tmpData[objectkey].country_code = country.key
      } else {
        this.tmpData.country_code = country.key
      }
    },

    phoneCountryChanged(country) {
      console.log('phoneCountryChanged', country)
      this.tmpData.phone_country_code = country.key
    },

    identityTypeChanged(value) {
      console.log('identityTypeChanged', value)
      this.tmpData.identity_type = value
    },

    applicantIsAccountHolderChanged(value) {
      this.tmpData.is_account_holder = value
      console.log('is_account_holder', value, this.tmpData.is_account_holder)
    },

    saveApplication() {
      this.$v.tmpData.$touch()
      this.$v.files.$touch()
      let allfiles = []
      for (const key in this.files) {
        if (this.files.hasOwnProperty(key)) {
          const element = this.files[key]
          allfiles = allfiles.concat(element)
        }
      }

      if (!this.$v.tmpData.$invalid && !this.$v.files.$invalid) {
        this.$emit('save', {
          data: this.tmpData,
          files: allfiles, //transform into array?
        })
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "schoolresidency": "Residência em aulas",
    "yes": "Sim",
    "no": "Não",
    "charsLimit": "Limite de {limit} caracteres.",
    "application": "Candidatura",
    "cancel": "Cancelar",
    "submit": "Submeter",
    "save": "Guardar",
    "uploadFiles": "Carregar ficheiros",
    "acceptance": "Aceitação",
    "fullName": "Nome completo",
    "address": "Morada",
    "postalCode": "Código Postal",
    "locality": "Localidade",
    "country": "País",
    "phoneCountry": "Indicativo do País",
    "phone": "Telefone",
    "id": {
      "label": "Documentos de identificação",
      "type": {
        "label": "Tipo",
        "placeholder": "Selecione um tipo",
        "cc": "Cartão de Cidadão",
        "passport": "Passaporte",
        "residence_permit": "Título de residência"
      },
      "number": "Número",
      "emittedDate": {
        "label": "Data de emissão",
        "placeholder": "Data"
      },
      "validDate": {
        "label": "Data de validade",
        "placeholder": "Data"
      }
    },
    "vat": "Número de contribuinte (português)",
    "personalData": "Dados pessoais",
    "enrolledCourse": {
      "title": "Curso inscrito",
      "label": "Identifique o curso em que está inscrito como estudante",
      "name": "Designação do curso",
      "academicYear": "Ano letivo",
      "institution": "Instituição onde se encontra inscrito",
      "options": {
        "technical_course": "Curso técnico superior profissional",
        "other": "Curso não conferente de grau académico integrado no projeto educativo de uma instituição de ensino superior",
        "graduate": "Licenciatura",
        "integrated_master": "Mestrado integrado",
        "master": "Mestrado",
        "phd": "Doutoramento",
        "not_applicable": "Não aplicável (titular de grau de doutor)"
      }
    },
    "differentAddress": "Tem uma morada diferente em período de aulas",
    "hasErrors": "Existem erros no formulário",
    "accountHolder": "Titular da conta",
    "isAccountHolder": "O/a requerente é um dos titulares da conta?",
    "academicInfo": {
      "title": "Informação académica",
      "studentNumber": {
        "label": "Número de estudante",
        "placeholder": "Indique o seu número de estudante da Universidade de Coimbra"
      },
      "course": {
        "label": "Curso",
        "placeholder": "Indique o nome do curso que frequenta"
      },
      "studyCycle": {
        "label": "Ciclo de Estudos",
        "placeholder": "Indique o ciclo de estudos que frequenta"
      },
      "institution": {
        "label": "Faculdade",
        "placeholder": "Indique a unidade de ensino que frequenta"
      }
    },
    "files": {
      "title": "Documentação obrigatória",
      "householdFiscalAddress": {
        "title": "Comprovativo da morada fiscal do agregado familiar e da morada em tempo de aulas"
      },
      "familyAllowance": {
        "title": "Comprovativo de ser beneficiário, à data do requerimento, de abono de família até ao 3.º escalão"
      },
      "permanentResidence": {
        "title": "Comprovativo de nacionalidade portuguesa ou título de autorização de residência permanente em Portugal ou outro comprovativo do requisito de elegibilidade previsto no artigo 5.º, alínea a) do RABEEES"
      },
      "nonExistenceOfDebts": {
        "title": "Certidões comprovativas da não existência de dívida tributária e contributiva"
      },
      "paymentReceipts": {
        "title": "Recibos de pagamento do alojamento existentes desde o início do ano letivo até à data de submissão do requerimento (recibos eletrónicos ou recibos + contrato de arrendamento)"
      },
      "iban": {
        "title": "Comprovativo do IBAN (número de identificação bancária)"
      }
    },
    "declarationOfHonor": "Declaração de honra",
    "accept_extraordinary_support_for_housing" : "Eu, acima identificado, venho requerer a atribuição do apoio extraordinário ao alojamento previsto no Despacho Nº3163/2023 de 9 de março",
    "entered_required_docs": "Para o efeito, anexo a este requerimento a documentação obrigatória necessária ao tratamento do pedido.",
    "accept_data_treatment": "Mais declaro que tomei conhecimento e que autorizo os termos do tratamento dos meus dados pessoais no âmbito deste pedido.",
    "notDefined": "Não definido",
    "errors": {
      "required": {
        "name": "Indique o seu nome",
        "address": "Indique a morada",
        "postalCode": "Indique o código postal",
        "locality": "Indique a localidade",
        "country": "Indique o seu país",
        "phoneNumber": "Indique o seu número de telefone",
        "identityType": "Indique um documento de identificação",
        "identityCode": "Indique o número do documento de identificação",
        "identityExpireDate": "Indique a data de validade",
        "vat": "Indique um número de contribuinte",
        "files": "Indique um ou mais ficheiros",
        "accept_extraordinary_support_for_housing": "Obrigatório",
        "entered_required_docs": "Obrigatório",
        "accept_data_treatment": "Obrigatório",
        "accountHolder": "Indique o nome do(a) titular da conta",
        "studentNumber": "Indique o número de estudante",
        "course": "Indique o curso",
        "studyCycle": "Indique o ciclo de estudos",
        "institution": "Indique a sua unidade de ensino",
        "iban": "Indique um IBAN português"
      },
      "invalid": {
        "name": "Nome inválido",
        "address": "Morada inválida",
        "postalCode": "Código postal inválido",
        "locality": "Localidade inválida",
        "country": "País inválido",
        "phoneNumber": "Número de telefone inválido",
        "identityCode": "Número inválido",
        "identityExpireDate": "Data inválida",
        "vat": "Número de contribuinte inválido",
        "files": "Ficheiro inválido",
        "accountHolder": "Titular da conta inválido",
        "studentNumber": "Número de estudante inválido",
        "course": "Curso inválido",
        "studyCycle": "Ciclo de estudos inválido",
        "institution": "Instituição inválida",
        "iban": "IBAN inválido"
      }
    },
    "close": "Cancelar"
  },
  "en": {
    "accept_extraordinary_support_for_housing" : "I, above identified, hereby request the award of the extraordinary support for housing provided for in the Decree",
    "entered_required_docs": "For this purpose, I attach to this request the mandatory documentation necessary for the processing of the application.",
    "accept_data_treatment": "I also declare that I have taken note and that I authorize the terms of the treatment of my personal data in the context of this request.",
    "differentAddress": "Has a different address during the school year",
    "files": {
      "title": "Required documentation",
      "householdFiscalAddress": {
        "title": "Proof of the fiscal address of the household and the address during the school year"
      },
      "familyAllowance": {
        "title": "Proof of being a beneficiary, as of the date of the application, of family allowance up to the 3rd scale"
      },
      "permanentResidence": {
        "title": "Proof of being a Portuguese national or of having a permanent residence permit in Portugal or other proof of the eligibility requirement provided for in Article 5, paragraph a) of the RABEEES"
      },
      "nonExistenceOfDebts": {
        "title": "Certificates proving the non-existence of tax and contributory debt"
      },
      "paymentReceipts": {
        "title": "Payment receipts of the accommodation existing since the beginning of the school year until the date of submission of the application (electronic receipts or receipts + rental contract)"
      },
      "iban": {
        "title": "Proof of IBAN (bank account number)"
      }
    },
    "accountHolder": "Account holder",
    "isAccountHolder": "Is account holder",
    "academicInfo": {
      "title": "Academic Information",
      "studentNumber": {
        "label": "Student Number",
        "placeholder": "Enter the Student Number"
      },
      "course": {
        "label": "Course",
        "placeholder": "Enter the Course"
      },
      "studyCycle": {
        "label": "Study Cycle",
        "placeholder": "Enter the Study Cycle"
      },
      "institution": {
        "label": "Institution",
        "placeholder": "Enter the Institution"
      }
    },
    "schoolresidency": "School Residency",
    "yes": "Yes",
    "no": "No",
    "charsLimit": "Limit of {limit} characters.",
    "application": "Application",
    "cancel": "Cancel",
    "submit": "Submit",
    "save": "Save",
    "uploadFiles": "Upload files",
    "acceptance": "Acceptance",
    "fullName": "Full Name",
    "address": "Address",
    "postalCode": "Postal Code",
    "locality": "City",
    "country": "Country",
    "phoneCountry": "Country calling code",
    "phone": "Phone",
    "email": "E-mail",
    "notDefined": "Not defined",
    "id": {
      "label": "Identification documents",
      "type": {
        "label": "Type",
        "placeholder": "Select a type",
        "cc": "Citizen ID card",
        "passport": "Passport",
        "residencePermit": "Residence permit"
      },
      "number": "Number",
      "emittedDate": {
        "label": "Emission date",
        "placeholder": "Date"
      },
      "validDate": {
        "label": "Validity date",
        "placeholder": "Date"
      }
    },
    "declarationOfHonor": "Declaration of Honor",
    "vat": "VAT Number",
    "personalData": "Personal details",
    "hasErrors": "There are errors in the form",
    "errors": {
      "required": {
        "name": "Name is required",
        "email": "Email is required",
        "address": "Address is required",
        "postalCode": "Postal code is required",
        "locality": "Enter your locality",
        "country": "Select your country",
        "phoneNumber": "Enter your phone number",
        "identityType": "Insert an identification document",
        "identityCode": "Enter your ID document number",
        "identityExpireDate": "Enter the expiry date",
        "vat": "Enter the VAT number",
        "files": "Add one or more files",
        "accept_extraordinary_support_for_housing": "Accept the extraordinary support for housing",
        "entered_required_docs": "Attach the required documentation",
        "accept_data_treatment": "Accept the treatment of your personal data",
        "accountHolder": "Indicate if you are the account holder",
        "studentNumber": "Indicate the student number",
        "course": "Indicate the course",
        "studyCycle": "Indicate the study cycle",
        "institution": "Indicate the institution"
      },
      "invalid": {
        "name": "Invalid name",
        "email": "Invalid email",
        "address": "Invalid address",
        "postalCode": "Invalid postal code",
        "locality": "Invalid locality",
        "country": "Invalid country",
        "phoneNumber": "Invalid phone number",
        "identityCode": "Invalid ID document number",
        "identityExpireDate": "Invalid date",
        "vat": "VAT",
        "scienceId": "Science ID",
        "habilitationLevel": "Invalid qualification level",
        "scientificArea": "Invalid scientific area",
        "academicYear": "Invalid academic year",
        "files": "Invalid file",
        "accountHolder": "Invalid account holder",
        "studentNumber": "Invalid student number",
        "course": "Invalid course",
        "studyCycle": "Invalid study cycle",
        "institution": "Invalid institution"
      }
    }
  },
  "close": "Cancel"
}
</i18n>
