<template>
  <fw-layout :full="isFullLayout" :back-to="backToPath" :loading="initialLoading" loading-title="Apoio S+">
    <template #header-nav>
      <div class="flex gap-4 items-center">
        <div class="flex-shrink-0">
          <fw-heading size="lg">
            <span class="text-gray-500">{{ call.prefix }}</span>
            <span class="font-bold">{{ call.code }}</span>
          </fw-heading>
        </div>
        <div v-if="view !== 'application' && ['draft', 'ended'].includes(call.state)">
          <fw-tag size="sm" :type="callStatusTagType[call.state]" custom-class="px-3">
            {{ $t(`callStatus.${call.state}`) }}
          </fw-tag>
        </div>
      </div>
    </template>

    <template v-if="hasAvailableActions" #header-toolbar>
      <BlockHeaderActions class="mx-5" :items="callActions" @publish="confirmPublish" @delete="confirmDelete" />
    </template>

    <template v-if="view !== 'application'" #main-sidebar>
      <SidebarManageCall :validations="validations" :people-validations="peopleValidations" :call-type="call?.type" />
    </template>

    <template #main-content>
      <PanelManageCallDashboard
        v-if="view == 'dashboard' && call && call.key"
        :call="call"
        :users="usersPermissions"
        :checks="checks"
        :user="users[call.user_key]"
        :loading="loading"
      ></PanelManageCallDashboard>
      <PanelManageCallPeople
        v-else-if="view == 'people'"
        :call="call"
        :users="usersPermissions"
        :loading="loading"
        :application="application"
        :validations="peopleValidations"
        :managers-keys="managers"
        :workers-keys="workers"
        @update-users="updateManagers"
        @delete-users="deleteManagers"
      ></PanelManageCallPeople>
      <PanelManageCallApplications
        v-else-if="view == 'applications'"
        :call="call"
        :users="users"
        :results="applications"
        :people-validations="peopleValidations"
        :loading="loading"
        :active-application-key="application ? application.key : 'none'"
        @search="getApplications"
      ></PanelManageCallApplications>
      <PanelManageCallMetadata
        v-else-if="view == 'metadata'"
        :call="call"
        :users="users"
        :loading="loading"
        :can-edit="validations.can_edit"
        @save-call="updateCall"
      ></PanelManageCallMetadata>
      <PanelManageCallActivity
        v-else-if="view == 'activity'"
        :call="call"
        :call-key="callKey"
      ></PanelManageCallActivity>
      <PanelManageCallNotifications
        v-else-if="view == 'notifications'"
        :call-key="callKey"
      ></PanelManageCallNotifications>
      <PanelManageApplication
        v-else-if="view == 'application'"
        :call="call"
        :applications="applications"
        :users="applicationUsers"
        :loading="loading"
        :application="application"
        :validations="applicationValidations"
        :people-validations="peopleValidations"
        :allow-copy-files="true"
        @added-message="addedMessage"
        @added-decision="updatedApplication"
        @updated-application="updatedApplication"
        @update-application-managers="updateApplicationManagers"
        @page-changed="pageChanged"
      ></PanelManageApplication>
      <PanelManageCallReports v-else-if="view == 'reports'" :call="call" />
    </template>
  </fw-layout>
</template>

<script>
import BlockHeaderActions from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderActions'
import PanelManageCallDashboard from '@/components/panels/manage/PanelManageCallDashboard'
import PanelManageCallMetadata from '@/components/panels/manage/PanelManageCallMetadata'
import PanelManageCallApplications from '@/components/panels/manage/PanelManageCallApplications'
import PanelManageCallPeople from '@/components/panels/manage/PanelManageCallPeople'
import PanelManageCallActivity from '@/components/panels/manage/PanelManageCallActivity'
import PanelManageCallNotifications from '@/components/panels/manage/PanelManageCallNotifications'
import PanelManageApplication from '@/components/panels/manage/PanelManageApplication'
import SidebarManageCall from '@/components/sidebars/SidebarManageCall'
import PanelManageCallReports from '@/components/panels/manage/PanelManageCallReports'

import { CALL_STATUS_TAG_COLORS } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    PanelManageCallDashboard,
    PanelManageCallPeople,
    PanelManageCallActivity,
    PanelManageCallNotifications,
    PanelManageCallMetadata,
    PanelManageCallApplications,
    BlockHeaderActions,
    PanelManageApplication,
    SidebarManageCall,
    PanelManageCallReports,
  },

  data() {
    return {
      call: {},
      users: {},
      usersPermissions: {},
      applicationUsers: {},
      applications: {},
      application: {},
      validations: {
        can_delete: false,
        can_publish: false,
        can_edit: false,
      },
      applicationValidations: {},
      peopleValidations: {},
      loading: true,
      initialLoading: true,
      savingData: false,
      callStatusTagType: CALL_STATUS_TAG_COLORS,
      managers: [],
      workers: [],
      showResposibleSelectorModal: false,
      searchQuery: null,

      defaultAdditionalData: {
        tax_tier: null,
        accommodation_type: null,
        ies_code: null,
        ies: null,
        course: null,
        course_code: null,
        course_type: null,
      },
    }
  },

  computed: {
    currentApplicationsPage() {
      return this.searchQuery?.page ?? 1
    },

    isFullLayout() {
      return this.view && this.view === 'application'
    },

    backToPath() {
      return this.view === 'application' ? `/manage/call/${this.callKey}/applications` : '/manage/calls'
    },

    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },

    callKey() {
      return this.$route.params.key
    },

    applicationKey() {
      return this.$route.params.applicationKey
    },

    view() {
      return this.$route.meta.view ?? 'dashboard'
    },

    callActions() {
      return [
        {
          canShowAction: this.validations.can_publish,
          actionName: 'publish',
          actionLabel: this.$t('publish'),
          addToCounter: true,
        },
        {
          canShowAction: this.validations.can_delete,
          customClass: 'text-red-400',
          actionName: 'delete',
          actionLabel: this.$t('delete'),
          addToCounter: false,
        },
      ]
    },

    // Checks
    checks() {
      return {
        isRunning: this.isRunning,
        isOpenToApplications: this.isOpenToApplications,
        isClosedToApplications: this.isClosedToApplications,
        isOpeningSoon: this.isOpeningSoon,
        isStudent: this.isStudent,
      }
    },

    hasAvailableActions() {
      return this.validations.can_publish || this.validations.can_delete
    },

    isRunning() {
      return !this.call.state.includes(['draft', 'closed'])
    },
    isOpenToApplications() {
      return this.call.time_to_end > 0 && this.call.time_to_start < 0 && this.call.state == 'published'
    },
    isClosedToApplications() {
      return this.call.time_to_end < 1
    },
    isOpeningSoon() {
      return this.call.time_to_start > 0 && this.call.state == 'published'
    },
    isStudent() {
      return this.call && this.call.validations && this.call.validations.is_student
    },
  },

  watch: {
    view(newVal) {
      if (newVal) this.getCurrentViewData()
    },
    applicationKey(key) {
      if (key) this.getApplication()
    },
  },

  mounted() {
    this.getCurrentViewData(true)
  },

  methods: {
    updateApplicationManagers(response) {
      this.application.managers = response.managers
      this.application = response.application
      //save managers objects
      //applicationUsers

      // Force app to be updated (make sure actions appear on the sidebar)
      this.getApplication()
    },
    selectUserSearch(selection) {
      console.log('selection', selection)
    },

    openResponsibleSelectorModal() {
      console.log('hey > openResponsibleSelectorModal')
      this.showResposibleSelectorModal = true
    },

    closeModal() {
      this.showResposibleSelectorModal = false
    },

    async getCurrentViewData(initialLoad) {
      if (this.view == 'people') {
        await this.getManagers()
      } else if (this.view == 'application') {
        await this.getApplication()
        if (typeof this.applications.applications == 'undefined' || this.applications.applications.length == 0) {
          this.getApplications()
        }
      }

      if (initialLoad || !this.view || ['dashboard', 'metadata'].includes(this.view)) {
        await this.getCall(initialLoad)
        await this.getManagers()
      }
    },

    async getCall(initialLoad) {
      this.loading = true
      try {
        const response = await this.api.getCall(this.callKey)
        console.log('getCall :>> ', response)
        this.call = response

        this.call.start_date = this.call.start_date ? Dates.buildCore(this.call.start_date).toDate() : null
        this.call.end_date = this.call.end_date ? Dates.buildCore(this.call.end_date).toDate() : null
        console.log('Parsed dates :>> ', this.call.start_date, this.call.end_date)

        if (!this.call.title || (!this.call.title.en && !this.call.title.pt)) this.call.title = { pt: '', en: '' }
        if (!this.call.description || (!this.call.description.en && !this.call.description.pt))
          this.call.description = { pt: '', en: '' }

        this.users = response.users
        console.log('response.validations', response.validations)
        if (response.validations) this.validations = response.validations

        // Set last call opened
        this.saveLocalLastCall()

        console.log('this.call :>> ', this.call)
      } catch (error) {
        console.log('Error getCall :>> ', error)
        this.handleErrors(error, 'Procedimento não encontrado.')
      } finally {
        // Run just once
        if (initialLoad) {
          setTimeout(() => {
            this.initialLoading = false
          }, 750)
        }
      }
      this.loading = false
    },

    pageChanged(page) {
      //Sidebar page change with the same query as the main search
      if (this.searchQuery == null) {
        this.searchQuery = {}
      }
      this.searchQuery.page = page
      this.getApplications(this.searchQuery)
    },

    async getApplications(query = null) {
      //save search query to be used in sidebar pageChanged
      this.searchQuery = query
      console.log('getApplications with query :>> ', query)
      this.loading = true

      try {
        const response = await this.api.getCallApplications(this.callKey, query)
        console.log('getApplications :>> ', response)
        this.call = response.call
        this.applications = response.applications
        this.users = response.users
        this.applicationUsers = response.users
        if (response.validations) this.validations = response.validations
      } catch (error) {
        console.log('Error getApplications :>> ', error)
        this.handleErrors(error, 'Procedimento não encontrado.')
      }

      this.loading = false
    },

    async getApplication() {
      this.loading = true

      try {
        const response = await this.api.getApplication(this.callKey, this.applicationKey)
        this.application = response.application
        this.call = response.call
        this.applicationValidations = response.validations

        // Fix some extra metadata
        this.application.rejected_reasons = this.application.rejected_reasons ?? []

        console.log('Application additional data from API >>', response.application.additional_data)
        console.log('Application additional data from DOM >>', this.application.additional_data)

        // Fix additional data
        if (!response.application.additional_data) {
          this.application.additional_data = { ...this.defaultAdditionalData }
        }

        console.log('getApplication :>>', response)
      } catch (error) {
        console.log('Error getApplication :>> ', error)
      }

      this.loading = false
    },

    async getManagers() {
      this.loading = true

      try {
        const response = await this.api.getCallManagers(this.callKey)
        console.log('getCallManagers :>> ', response)
        //this.call = response.call
        this.managers = response.permissions.manager ?? []
        this.workers = response.permissions.worker ?? []
        this.usersPermissions = response.users
        if (response.validations) {
          this.peopleValidations = response.validations
        }
      } catch (error) {
        console.log('Error getCallManagers :>> ', error)
        this.handleErrors(error, 'Procedimento não encontrado.')
      }

      this.loading = false
    },

    async updateManagers(selection) {
      console.log('updateManagers with selection :>> ', selection)
      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateCallManagers(this.callKey, selection.usersAndRoles)
          console.log('updateCallManagers :>> ', response)
          if (response.permissions.manager) this.managers = response.permissions.manager
          if (response.permissions.worker) this.workers = response.permissions.worker
          if (response.users) this.usersPermissions = response.users
        },
        () => {
          this.savingData = false
        }
      )
    },

    async deleteManagers(selection) {
      console.log('deleteManagers with selection :>> ', selection)
      this.savingData = true

      utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteCallManagers(this.callKey, selection.usersAndRoles)
        console.log('deleteCallManagers :>> ', response)
        if (response.permissions && response.permissions.manager) this.managers = response.permissions.manager
        if (response.permissions && response.permissions.worker) this.workers = response.permissions.worker
        if (response.users) this.usersPermissions = response.users

        // Check for errors (if any) - even when response status is 200
        // (this is because the API returns 200 even when there are errors)
        // Must be fixed
        if (response.__errors__) {
          const errorKey = utils.errors(response).getKey()
          if (errorKey === 'Forbidden') {
            this.$buefy.snackbar.open({
              message:
                'Não foi possível remover o(a) utilizador(a) porque pode estar associado(a) a uma ou mais candidaturas.',
              type: 'is-danger',
            })
          } else {
            this.$buefy.snackbar.open({
              message: 'Ocorreu um erro não esperado ao tentar eliminar o utilizador.',
              type: 'is-danger',
            })
          }
        }
      })

      this.savingData = false
    },

    async updateCall(payload, withState = false) {
      console.log('updateCall with payload :>> ', payload)
      this.savingData = true

      if (!withState) {
        delete payload['state']
      }

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateCall(this.callKey, payload)
          console.log('updateCall :>> ', response)
          this.call = response
          this.call.start_date = Dates.buildCore(response.start_date).toDate()
          this.call.end_date = Dates.buildCore(response.end_date).toDate()
          if (response.validations) this.validations = response.validations
          this.$buefy.snackbar.open({
            message: 'Metadados atualizados com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    confirmPublish() {
      if (!this.validations.can_publish) return
      this.$buefy.dialog.confirm({
        confirmText: 'Publicar',
        cancelText: 'Cancelar',
        title: 'Publicar procedimento',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>Publicar</strong> este procedimento?`,
        onConfirm: () => {
          this.updateCall({ state: 'published' }, true)
        },
      })
    },

    confirmDelete() {
      if (!this.validations.can_delete) return
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        title: 'Eliminar procedimento',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>Eliminar</strong> este procedimento? Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteCall()
        },
      })
    },

    async deleteCall() {
      if (!this.validations.can_delete) return
      try {
        await this.api.deleteCall(this.callKey)
        utils.sleep(250)
        this.$buefy.snackbar.open({
          message: `Procedimento eliminado com sucesso.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 2000,
          queue: true,
        })
        this.loading = false
        this.$router.push({ name: 'manage-calls' })
      } catch (error) {
        console.error('deleteCall :>>', error)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao eliminar o procedimento.',
          type: 'is-danger',
        })
      }
    },

    addedMessage(response) {
      this.application.state = response.application.state
    },

    updatedApplication(response) {
      console.log('updatedApplication :>> ', response)
      this.application = response.application
      this.applicationValidations = response.validations

      if (!this.application.additional_data) {
        this.application.additional_data = this.defaultAdditionalData
      }
    },

    handleErrors(error, notFoundMessage) {
      const errorKey = utils.errors(error).getKey()
      if (errorKey && errorKey == 'Forbidden') {
        return this.$router.push({ name: 'forbidden' })
      }
      if (errorKey && errorKey == 'callCodeAlreadyExists') {
        this.$buefy.dialog.alert({
          title: this.$t('callCodeAlreadyExists.title'),
          message: this.$t('callCodeAlreadyExists.message'),
          type: 'is-danger',
        })

        return
      } else if (errorKey && errorKey == 'NotFound') {
        this.$buefy.snackbar.open({
          message: notFoundMessage,
          type: 'is-danger',
        })
        return this.$router.push({ name: 'calls' })
      }
    },

    // Utils
    saveLocalLastCall() {
      const call = {
        code: this.call.code,
        key: this.call.key,
        prefix: this.call.prefix,
      }
      localStorage.setItem('last-call-open', JSON.stringify(call))
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "callCodeAlreadyExists": {
      "title": "Código já existe",
      "message": "Já existe um procedimento com o código que tentou definir. Por favor, indique outro para guardar as alterações."
    },
    "status": "Estado",
    "publish": "Publicar",
    "delete": "Eliminar",
    "callStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "deleted": "Removido"
    }
  },
  "en": {
    "callCodeAlreadyExists": {
      "title": "Code already exists",
      "message": "There is already a row with the code you tried to set. Please enter another one to save your changes."
    },
    "publish": "Publish",
    "delete": "Delete",
    "status": "Status",
    "callStatus": {
      "draft": "Draft",
      "published": "Published",
      "deleted": "Deleted"
    }
  }
}
</i18n>
