<template>
  <div v-if="call" class="relative">
    <fw-panel :title="$t('call')" featured sticky :loading="savingData" after-loading-checked>
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="canEdit && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="canEdit" class="flex gap-5">
            <fw-button v-if="canEdit" type="primary" @click.native="saveProc()">{{ $t('save') }}</fw-button>
          </div>
        </div>
      </template>
    </fw-panel>

    <LoadingPlaceholder v-if="loading" />

    <div v-else>
      <fw-panel :title="$t('generalDetails')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <div>
          <fw-label>{{ $t('callCode') }}</fw-label>
          <fw-heading size="h2">{{ call.prefix + call.code }}</fw-heading>
        </div>

        <div>
          <fw-label>{{ $t('procType.label') }}</fw-label>
          <div class="font-medium">
            {{ $t(`procType.${call.type}`) }}
          </div>
        </div>

        <div>
          <fw-label>{{ $t('title.label') }}</fw-label>
          <div v-if="!canEdit" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div class="flex-shrink-0">
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="call.title && call.title[lang]">{{ call.title[lang] }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="call.title"
            :disabled="!canEdit"
            :multiline="false"
            :maxlength="250"
            :disable-autowrite="true"
            :rows="3"
            :class="{
              error: $v.call.title.$error,
            }"
            :placeholder="{
              pt: $t('title.placeholder.pt'),
              en: $t('title.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.call.title.$error" error>
            {{ $t('title.required') }}
          </fw-tip>
        </div>

        <div>
          <div v-if="!canEdit" class="flex flex-col gap-3">
            <fw-label>{{ $t('description.label') }}</fw-label>
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div class="flex-shrink-0">
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="call.description && call.description[lang]" v-html="call.description[lang]"></span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>
          <div v-else>
            <fw-label>Descrição em Português</fw-label>
            <fw-editor v-model="call.description['pt']" class="description_editor"></fw-editor>
            <fw-label>Descrição em Inglês</fw-label>
            <fw-editor v-model="call.description['en']" class="description_editor"></fw-editor>
          </div>

          <TranslatedInput
            v-if="false"
            v-model="call.description"
            :disabled="!canEdit"
            :multiline="true"
            :disable-autowrite="true"
            :rows="3"
            :class="{
              error: $v.call.description.$error,
            }"
            :placeholder="{
              pt: $t('description.placeholder.pt'),
              en: $t('description.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.call.description.$error" error>
            {{ $t('description.required') }}
          </fw-tip>
        </div>
      </fw-panel>
      <fw-panel :title="'Calendarização'" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <div class="flex flex-row gap-5">
          <div class="sm:w-1/2">
            <span class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('startDate.label') }}</span>
            <div v-if="!canEdit" class="font-medium">
              {{ call.start_date | formatDate }}
            </div>
            <b-datepicker
              v-else
              v-model="call.start_date"
              :placeholder="$t('date.placeholder')"
              icon="calendar-alt"
              locale="pt-PT"
              :min-date="minDate"
              trap-focus
              :class="{
                error: $v.call.start_date.$error,
              }"
              :disabled="!canEdit && canEdit"
              @icon-right-click="call.start_date = null"
            >
            </b-datepicker>
            <fw-tip v-if="$v.call.start_date.$error" error>
              <span v-if="!$v.call.start_date.min">{{ $t('startDate.min') }}</span>
              <span v-else>{{ $t('startDate.required') }}</span>
            </fw-tip>
          </div>

          <div class="sm:w-1/2">
            <span class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('endDate.label') }}</span>
            <div v-if="!canEdit" class="font-medium">{{ call.end_date | formatDate }}</div>
            <b-datepicker
              v-else
              v-model="call.end_date"
              :class="{
                error: $v.call.end_date.$error,
              }"
              :placeholder="$t('date.placeholder')"
              icon="calendar-alt"
              trap-focus
              :min-date="call.start_date ?? minDate"
              locale="pt-PT"
              position="is-bottom-left"
              :disabled="!canEdit && canEdit"
              @icon-right-click="call.end_date = null"
            >
            </b-datepicker>

            <fw-tip v-if="$v.call.end_date.$error" error>
              <span v-if="!$v.call.end_date.min">{{ $t('endDate.min') }}</span>
              <span v-else>{{ $t('endDate.required') }}</span>
            </fw-tip>
          </div>
        </div>
      </fw-panel>
    </div>
  </div>
</template>

<script>
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import { required, maxLength, minLength, minValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { CALL_TYPES } from '@/utils/index.js'

export default {
  components: {
    TranslatedInput,
    LoadingPlaceholder,
  },

  props: {
    call: {
      type: Object,
      default: () => {
        return {}
      },
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      callTypes: CALL_TYPES,
      minEndDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },
    minDate() {
      return this.call && this.call.state === 'published' ? null : new Date()
    },
  },

  validations() {
    return {
      call: {
        type: { required },
        title: { required, min: minLength(2), max: maxLength(250) },
        description: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
        start_date: { required, min: minValue(this.minDate) },
        end_date: { required, min: minValue(this.start_date || this.minDate) },
      },
    }
  },

  methods: {
    saveProc() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const payload = { ...this.call }
        this.$emit('save-call', payload)
      }
    },
  },
}
</script>
<i18n>
{
  "pt": {
    "call": "Metadados",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "generalDetails": "Detalhes gerais",
    "callCode": "Código do procedimento",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "title": {
      "label": "Título",
      "placeholder": {
        "pt": "Título em Português",
        "en": "Título em Inglês"
      },
      "required": "Insira um título em Portugês e Inglês."
    },
    "description": {
      "label": "Descrição",
      "placeholder": {
        "pt": "Descrição em Português",
        "en": "Descrição em Inglês"
      },
      "required": "Insira uma descrição em Portugês e Inglês."
    },
    "charsLimit": "Limite de {limit} caracteres.",
    "procType": {
      "label": "Tipo",
      "placeholder": "Escolha um tipo",
      "scholarship": "Scholarship",
      "occupational_medicine": "Medicina do Trabalho",
      "required": "Escolha o tipo do procedimento."
    },
    "notDefined": "Não definido"
  },
  "en": {
    "generalDetails": "General details",
    "callCode": "Procedure code",
    "call": "Metadata",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "title": {
      "label": "Title",
      "placeholder": {
        "pt": "Title in Portuguese",
        "en": "English title"
      },
      "required": "Enter a title in English and Portuguese."
    },
    "description": {
      "label": "Description",
      "placeholder": {
        "pt": "Description in Portuguese",
        "en": "Description in English"
      },
      "required": "Enter a description in English and Portuguese."
    },
    "charsLimit": "Limit of {limit} characters.",
    "procType": {
      "label": "Tipo",
      "placeholder": "Escolha um tipo",
      "scholarship": "Scholarship",
      "occupational_medicine": "Occupational Medicine",
      "required": "Enter the call type."
    },
    "notDefined": "Not defined"
  }
}
</i18n>
