<template>
  <button
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex gap-5 items-center"
    @click="$emit('open', application.key)"
  >
    <div>
      <fw-avatar size="xs" :user="user" class="flex-shrink-0" />
    </div>
    <div class="flex-1 text-left">
      <v-clamp autoresize :max-lines="1" class="font-medium">
        {{ user.full_name }}
      </v-clamp>
      <div class="text-xs leading-3">{{ user.number }}</div>
    </div>
    <div class="text-xs text-gray-500 w-36 text-left flex gap-2 items-center">
      <fw-dot :color="application.has_updates ? 'primary' : 'light'" inline :ping="application.has_updates" />
      <span>{{ application.updated_date | formatDateTime }}</span>
    </div>
    <div>
      <fw-tag :type="applicationStates[application.state].color" class="ml-auto">
        <v-clamp autoresize :max-lines="1" class="w-24">
          {{ applicationStates[application.state].label[language] }}
        </v-clamp>
      </fw-tag>
    </div>
    <div class="w-8">
      <div v-if="firstManager" class="relative">
        <fw-avatar size="xs" :user="firstManager" />
        <fw-badge v-if="moreManagersTotal" class="-mr-1">+{{ moreManagersTotal }}</fw-badge>
      </div>
      <div v-else class="flex items-center justify-center">
        <fw-icon-user-unfollow class="text-gray-300 w-8 h-8 bg-gray-50 rounded-lg p-1" />
      </div>
    </div>
  </button>
</template>

<script>
import { APPLICATION_STATES } from '@/utils/index.js'

export default {
  props: {
    application: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      applicationStates: APPLICATION_STATES['scholarship'],
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },
    firstManager() {
      return this.application &&
        this.application.managers &&
        this.application.managers.length &&
        this.users &&
        this.users[this.application.managers[0]]
        ? this.users[this.application.managers[0]]
        : null
    },
    moreManagersTotal() {
      return this.firstManager && this.application.managers.length > 1 ? this.application.managers.length - 1 : 0
    },
  },
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
