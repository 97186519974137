<template>
  <ChoosePeopleModal
    :title="$t('chooseAssignees')"
    :instructions="$t('selectNewAssignees')"
    :multiselect="true"
    :selected="managers"
    :auto-close="false"
    :searchable="false"
    :users-list="availableUsers"
    @selected="newSelection"
    @close="close"
  >
    <template v-if="managers && managers.length" #previous>
      <div class="flex flex-col gap-3 px-5 mt-1.5">
        <div class="flex-shrink-0 rounded-xl bg-gray-100 px-3 py-2">
          <fw-label>{{ $t('currentAssignees') }}</fw-label>
          <div>
            <Person v-for="manager_key in managers" :key="manager_key" :person="users[manager_key]"></Person>
          </div>
        </div>
      </div>
    </template>
  </ChoosePeopleModal>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'

export default {
  components: { ChoosePeopleModal, Person },
  props: {
    managers: {
      type: Array,
      default: () => [],
    },
    availableUsers: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Object,
      default: () => {},
    },
    callKey: {
      type: String,
      default: '',
    },
    applicationKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newManagers: [],
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    confirmDisabled() {
      return this.newManagers.length < 1
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    newSelection(users) {
      console.log('selected users :>> ', users)
      this.newManagers = users
      this.$emit(
        'add',
        this.newManagers.map(el => el.key)
      )
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "chooseAssignees": "Escolha responsáveis",
    "selectNewAssignees": "Selecione os utilizadores que pretende associar a este processo.",
    "currentAssignees": "Responsáveis atuais"
  },
  "en": {
    "chooseAssignees": "Escolha responsáveis",
    "selectNewAssignees": "Selecione os utilizadores que pretende associar a este processo.",
    "currentAssignees": "Responsáveis atuais"
  }
}
</i18n>
