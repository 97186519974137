<template>
  <div>
    <ChoosePeopleModal
      :title="title"
      :instructions="instructions"
      :multiselect="multiselect"
      :not-alowed-users="notAlowedUsers"
      :invite-people="false"
      :endpoint="endpoint"
      @selected-updated="selectedPeople"
      @confirm="saveSelection"
      @close="$emit('close')"
    >
      <template #after-list>
        <div v-if="selectedUsers.length" class="ml-2">
          <div class="mt-5">
            <fw-label>{{ $t('queuesToAdd') }}</fw-label>
            <div class="flex gap-1 items-center">
              <b-checkbox
                v-for="queue in queues"
                :key="queue.key"
                v-model="selectedQueues"
                :native-value="queue.key"
                class="pt-2"
                >{{ queue.prefix | uppercase }}</b-checkbox
              >
            </div>

            <fw-tip v-if="$v.selectedQueues.$error" error>
              {{ $t('chooseAtLeastOneQueue') }}
            </fw-tip>
          </div>
        </div>
      </template>
    </ChoosePeopleModal>

    <fw-tip v-if="!selectedUsers.length && hasErrors" error>
      <span> {{ $t('selectAtLeastOne') }}</span>
    </fw-tip>
  </div>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'

import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    ChoosePeopleModal,
  },
  props: {
    multiselect: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    instructions: {
      type: String,
      default: '',
    },
    notAlowedUsers: {
      //not alowed user keys
      type: Array,
      default: function() {
        return []
      },
    },
    endpoint: {
      type: Function,
    },
    spaceKey: {
      type: String,
      default: '',
    },
    queues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hasErrors: false,
      searchQueryId: '',
      selectedUsers: [],
      selectedRoles: [],
      selectedQueues: [],
      debounceTimer: null,
      searching: false,
      totalPages: 1,
      totalLimit: 20,
      page: 1,
    }
  },

  validations: {
    selectedUsers: { required, min: minLength(1) },
    selectedQueues: { required, min: minLength(1) },
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    confirmDisabled() {
      return this.$v.$invalid
    },
  },

  methods: {
    selectedPeople(selected) {
      console.log('Select THIS ONE', selected)
      this.selectedUsers = selected
    },

    saveSelection() {
      this.$v.$touch()
      console.log('this.$v.$invalid :>> ', this.$v.$invalid)
      if (this.$v.$invalid) return

      this.$emit('selected', {
        users: this.selectedUsers.map(el => el.key),
        queues: this.selectedQueues,
        refresh: true,
      })

      this.$v.$reset
      this.selectedUsers = []
      this.selectedRoles = []
      this.selectedQueues = []
    },
  },
}
</script>

<style>
.invite-users.keyboard_input .textarea:focus,
.invite-users.keyboard_input .input:focus,
.invite-users.keyboard_input .taginput .taginput-container.is-focusable:focus,
.invite-users.keyboard_input .datepicker .dropdown .input:focus,
.invite-users.keyboard_input .datepicker .dropdown-trigger .input[readonly]:focus,
.invite-users.keyboard_input button:focus,
.invite-users.keyboard_input select:focus,
.invite-users.keyboard_input button:focus {
  outline: solid 2px rgba(3, 164, 121, 0.7) !important;
  outline-offset: 2px !important;
}
</style>

<i18n>
{
  "pt": {
    "noDataFound": "Sem resultados",
    "searchInUC": "Pesquisar na UC",
    "useInstitutionalAccount": "Por favor use a conta institucional do(a) utilizador(a)",
    "search": "Pesquisar...",
    "searchText": "Pesquisar",
    "results": "Resultados",
    "limitedNumResults": "Esta pesquisa apresenta um número limitado de resultados",
    "defineKeywords": "Defina melhor as palavras chaves para encontrar o registo que procura",
    "permissionsToAdd": "Permissões para adicionar",
    "chooseAtLeastOne": "Escolha pelo menos uma permissão",
    "name": "Nome",
    "permissions": "Permissões",
    "cancel": "Cancelar",
    "add": "Adicionar",
    "selectAtLeastOne": "Escolha pelo menos um(a) utilizador(a)",
    "queuesToAdd": "Filas",
    "chooseAtLeastOneQueue": "Escolha pelo menos uma fila"
  },
  "en": {
    "noDataFound": "No data found",
    "searchInUC": "Search in UC",
    "useInstitutionalAccount": "Please use the user's institutional account",
    "search": "Search...",
    "searchText": "Search",
    "results": "Results",
    "limitedNumResults": "This search presents a limited number of results",
    "defineKeywords": "Better define your keywords to find the record you are looking for",
    "permissionsToAdd": "Permissions to add",
    "chooseAtLeastOne": "Choose at least one permission",
    "permissions": "Permissions",
    "cancel": "Cancel",
    "add": "Add",
    "selectAtLeastOne": "Choose at least one user",
    "queuesToAdd": "Queues too add",
    "chooseAtLeastOneQueue": "Choose at least one queue"
  }
}
</i18n>
