var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.call)?_c('div',{staticClass:"relative"},[_c('fw-panel',{attrs:{"title":_vm.$t('call'),"featured":"","sticky":"","loading":_vm.savingData,"after-loading-checked":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"flex gap-1 items-center"},[(_vm.canEdit && _vm.$v.$error)?_c('div',{staticClass:"p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"},[_c('fw-icon-error-warning',{staticClass:"w-6 h-6"}),_c('span',{staticClass:"hidden lg:block"},[_vm._v(_vm._s(_vm.$t('thereAreErrors')))])],1):_vm._e(),(_vm.canEdit)?_c('div',{staticClass:"flex gap-5"},[(_vm.canEdit)?_c('fw-button',{attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.saveProc()}}},[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e()],1):_vm._e()])]},proxy:true}],null,false,812188067)}),(_vm.loading)?_c('LoadingPlaceholder'):_c('div',[_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('generalDetails'),"boxed":"lg","custom-class":"bg-white flex flex-col gap-3"}},[_c('div',[_c('fw-label',[_vm._v(_vm._s(_vm.$t('callCode')))]),_c('fw-heading',{attrs:{"size":"h2"}},[_vm._v(_vm._s(_vm.call.prefix + _vm.call.code))])],1),_c('div',[_c('fw-label',[_vm._v(_vm._s(_vm.$t('procType.label')))]),_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.$t(`procType.${_vm.call.type}`))+" ")])],1),_c('div',[_c('fw-label',[_vm._v(_vm._s(_vm.$t('title.label')))]),(!_vm.canEdit)?_c('div',{staticClass:"flex flex-col gap-3"},_vm._l((_vm.langs),function(lang){return _c('div',{key:lang,staticClass:"flex gap-3"},[_c('div',{staticClass:"flex-shrink-0"},[_c('fw-tag',{staticClass:"uppercase"},[_vm._v(_vm._s(lang))])],1),(_vm.call.title && _vm.call.title[lang])?_c('span',[_vm._v(_vm._s(_vm.call.title[lang]))]):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.$t('notDefined')))])],1)}),0):_c('TranslatedInput',{class:{
            error: _vm.$v.call.title.$error,
          },attrs:{"disabled":!_vm.canEdit,"multiline":false,"maxlength":250,"disable-autowrite":true,"rows":3,"placeholder":{
            pt: _vm.$t('title.placeholder.pt'),
            en: _vm.$t('title.placeholder.en'),
          }},model:{value:(_vm.call.title),callback:function ($$v) {_vm.$set(_vm.call, "title", $$v)},expression:"call.title"}}),(_vm.$v.call.title.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" "+_vm._s(_vm.$t('title.required'))+" ")]):_vm._e()],1),_c('div',[(!_vm.canEdit)?_c('div',{staticClass:"flex flex-col gap-3"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('description.label')))]),_vm._l((_vm.langs),function(lang){return _c('div',{key:lang,staticClass:"flex gap-3"},[_c('div',{staticClass:"flex-shrink-0"},[_c('fw-tag',{staticClass:"uppercase"},[_vm._v(_vm._s(lang))])],1),(_vm.call.description && _vm.call.description[lang])?_c('span',{domProps:{"innerHTML":_vm._s(_vm.call.description[lang])}}):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.$t('notDefined')))])],1)})],2):_c('div',[_c('fw-label',[_vm._v("Descrição em Português")]),_c('fw-editor',{staticClass:"description_editor",model:{value:(_vm.call.description['pt']),callback:function ($$v) {_vm.$set(_vm.call.description, 'pt', $$v)},expression:"call.description['pt']"}}),_c('fw-label',[_vm._v("Descrição em Inglês")]),_c('fw-editor',{staticClass:"description_editor",model:{value:(_vm.call.description['en']),callback:function ($$v) {_vm.$set(_vm.call.description, 'en', $$v)},expression:"call.description['en']"}})],1),(false)?_c('TranslatedInput',{class:{
            error: _vm.$v.call.description.$error,
          },attrs:{"disabled":!_vm.canEdit,"multiline":true,"disable-autowrite":true,"rows":3,"placeholder":{
            pt: _vm.$t('description.placeholder.pt'),
            en: _vm.$t('description.placeholder.en'),
          }},model:{value:(_vm.call.description),callback:function ($$v) {_vm.$set(_vm.call, "description", $$v)},expression:"call.description"}}):_vm._e(),(_vm.$v.call.description.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" "+_vm._s(_vm.$t('description.required'))+" ")]):_vm._e()],1)]),_c('fw-panel',{staticClass:"my-5",attrs:{"title":'Calendarização',"boxed":"lg","custom-class":"bg-white flex flex-col gap-3"}},[_c('div',{staticClass:"flex flex-row gap-5"},[_c('div',{staticClass:"sm:w-1/2"},[_c('span',{staticClass:"font-semibold text-gray-500 mb-1 text-xs"},[_vm._v(_vm._s(_vm.$t('startDate.label')))]),(!_vm.canEdit)?_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.call.start_date))+" ")]):_c('b-datepicker',{class:{
              error: _vm.$v.call.start_date.$error,
            },attrs:{"placeholder":_vm.$t('date.placeholder'),"icon":"calendar-alt","locale":"pt-PT","min-date":_vm.minDate,"trap-focus":"","disabled":!_vm.canEdit && _vm.canEdit},on:{"icon-right-click":function($event){_vm.call.start_date = null}},model:{value:(_vm.call.start_date),callback:function ($$v) {_vm.$set(_vm.call, "start_date", $$v)},expression:"call.start_date"}}),(_vm.$v.call.start_date.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.call.start_date.min)?_c('span',[_vm._v(_vm._s(_vm.$t('startDate.min')))]):_c('span',[_vm._v(_vm._s(_vm.$t('startDate.required')))])]):_vm._e()],1),_c('div',{staticClass:"sm:w-1/2"},[_c('span',{staticClass:"font-semibold text-gray-500 mb-1 text-xs"},[_vm._v(_vm._s(_vm.$t('endDate.label')))]),(!_vm.canEdit)?_c('div',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.call.end_date)))]):_c('b-datepicker',{class:{
              error: _vm.$v.call.end_date.$error,
            },attrs:{"placeholder":_vm.$t('date.placeholder'),"icon":"calendar-alt","trap-focus":"","min-date":_vm.call.start_date ?? _vm.minDate,"locale":"pt-PT","position":"is-bottom-left","disabled":!_vm.canEdit && _vm.canEdit},on:{"icon-right-click":function($event){_vm.call.end_date = null}},model:{value:(_vm.call.end_date),callback:function ($$v) {_vm.$set(_vm.call, "end_date", $$v)},expression:"call.end_date"}}),(_vm.$v.call.end_date.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.call.end_date.min)?_c('span',[_vm._v(_vm._s(_vm.$t('endDate.min')))]):_c('span',[_vm._v(_vm._s(_vm.$t('endDate.required')))])]):_vm._e()],1)])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }