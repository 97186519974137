<template>
  <fw-panel title="Candidaturas" featured>
    <template v-if="isManagerOrCallManager" #toolbar>
      <b-dropdown aria-role="list" position="is-bottom-left" class="paddingless">
        <fw-button-dropdown slot="trigger" type="transparent" class="flex flex-col" :chevron="false" label="Operações">
          <fw-icon-more class="w-6 h-6" />
        </fw-button-dropdown>
        <b-dropdown-item aria-role="menuitem" @click.native="openApplicationsDistributionModal">
          Distribuir candidaturas
        </b-dropdown-item>
        <b-dropdown-item v-if="false" aria-role="menuitem">
          Exportar mapa DGES (.csv)
        </b-dropdown-item>
        <b-dropdown-item v-if="false" aria-role="menuitem">
          Exportar mapa SASUC (.csv)
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <ContextualSearch
      v-if="showSearch"
      :loading="loading"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :applied-users="userFilters"
      :multifilter="multifilter"
      :start-value="searchInput"
      :start-period="startPeriod"
      :end-period="endPeriod"
      :order-by-options="orderBy"
      :users="users"
      :available-users-keys="managersKeys"
      :show-time-period="true"
      :show-user-picker="true"
      :user-picker-label="'Responsáveis'"
      :time-period-label="$t('createdDate')"
      :can-close="activeModal === null"
      :scrollable="false"
      @sort-order-changed="sortOrderChanged"
      @search="search"
    >
      <template #tags>
        <FilterTag
          v-for="(userKey, u) in userFilters"
          :key="'filter_user_' + userKey"
          :text="users[userKey].name ?? 'Sem nome'"
          :show-close-button="true"
          @close="deleteUserFilter(u)"
        ></FilterTag>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod != null && endPeriod != null"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="applications ? applications.length : 0"
      :counter-total="totalResults"
      boxed
      class="my-5"
      custom-class="bg-white p-0"
    >
      <fw-panel-info v-if="!loading && (!applications || !applications.length)" type="basic">
        {{ $t('noApplications') }}
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />

      <RecycleScroller
        v-else
        ref="applicationsList"
        v-slot="{ item, index }"
        :items="applications"
        :item-size="50"
        :buffer="25"
        key-field="key"
        page-mode
      >
        <div class="p-1 border-b border-gray-100">
          <RecordApplication
            :application="item"
            :call-code="call.prefix + call.code"
            :user="users[item.user_key]"
            :users="users"
            @open="goToApplication(item.key)"
          ></RecordApplication>
        </div>
      </RecycleScroller>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />

    <fw-modal
      v-if="isManagerOrCallManager"
      title="Distribuir candidaturas"
      :active.sync="distribution.activeModal"
      boxed="sm"
      size="3xl"
      @close="closeDistributionModal()"
    >
      <div class="text-center flex flex-col gap-3">
        <div
          v-if="distribution.actualState && !distribution.running && !Object.keys(distribution.results).length"
          class="py-3"
        >
          <div class="inline-flex relative">
            <fw-tag
              :type="distribution.actualState.applications ? 'light-orange' : 'light'"
              size="2xl"
              :class="{ 'opacity-40': !distribution.actualState.applications }"
            >
              <fw-icon-survey class="w-7 h-7 mr-2" /> {{ distribution.actualState.applications }}
            </fw-tag>
            <fw-icon-check
              v-if="!distribution.actualState.applications"
              class="w-5 h-5 text-white bg-primary rounded-full p-0.5 absolute -top-1 -right-2"
            />
          </div>
          <div
            class="font-semibold text-sm mt-2"
            :class="{
              'text-yellow-600': distribution.actualState.applications,
              'text-gray-500': !distribution.actualState.applications,
            }"
          >
            Candidaturas sem responsável
          </div>
          <div v-if="canExecuteDistribution" class="mt-5 bg-gray-100 rounded-xl px-3 pb-3 py-1">
            <fw-label size="xs" class="opacity-60">Operadores disponíveis</fw-label>
            <div class="flex gap-2 justify-center mt-1.5">
              <div
                v-for="managerKey in distribution.actualState.managers"
                :key="managerKey"
                class="w-20 flex flex-col gap-1 justify-center"
              >
                <div class="flex justify-center">
                  <fw-avatar size="xs" :user="distribution.actualState.users[managerKey]" />
                </div>
                <div class="text-xs font-semibold text-center">
                  <v-clamp autoresize :max-lines="1">{{ user.name }}</v-clamp>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="distribution.running || Object.keys(distribution.results).length"
          class="text-center p-5 bg-primary bg-opacity-5 rounded-xl min-h-24 h-full flex items-center justify-center"
        >
          <div v-if="distribution.running">
            <div class="py-3">
              <fw-icon-loading class="w-8 h-8 text-primary inline-flex" />
            </div>
            <div class="text-xs text-primary animate-pulse">A distribuir candidaturas...</div>
          </div>
          <div v-else-if="Object.keys(distribution.results).length">
            <div class="font-semibold text-center text-primary text-sm mb-8">
              <span>Candidaturas distribuídas</span>
              <fw-icon-checkbox-circle class="w-4 h-4 text-primary inline-flex ml-1" />
            </div>
            <div class="flex gap-2 justify-center">
              <div
                v-for="(managerAddedApplications, managerKey) in distribution.results.managers"
                :key="managerKey"
                class="flex flex-col gap-2 justify-center items-center w-32"
              >
                <div class="relative">
                  <fw-avatar :user="distribution.results.users[managerKey]" />
                  <fw-badge class="-mr-1">+{{ managerAddedApplications.length }}</fw-badge>
                </div>
                <div class="text-xs font-semibold text-center">
                  <v-clamp autoresize :max-lines="1">{{ user.name }}</v-clamp>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!Object.keys(distribution.results).length && !distribution.running" class="text-xs text-gray-500">
          <div v-if="!canExecuteDistribution && !distribution.actualState.applications">
            Todas as candidaturas parecem estar atribuídas a (pelo menos) um operador e não poderá executar o mecanismo
            de distribuição automática.
          </div>
          <div v-else-if="!distribution.actualState.managers.length">
            Não existem operadores disponíveis para atribuir candidaturas. Por favor, adicione pelo menos um(a)
            operador(a) e volte a esta opção.
          </div>
          <div v-else>
            Carregue no botão seguinte para atribuir automaticamente todas as candidaturas sem responsável aos
            operadores disponíveis.
          </div>
        </div>
        <div>
          <fw-button
            v-if="canExecuteDistribution && !Object.keys(distribution.results).length"
            :type="canExecuteDistribution && !distribution.running ? 'primary' : 'disabled'"
            expanded
            :disabled="distribution.actualState.applications < 1 || distribution.running"
            @click.native="doCallApplicationsDistribution"
            >Atribuir automaticamente</fw-button
          >
          <fw-button v-else type="link-muted" @click.native="closeDistributionModal">Fechar janela</fw-button>
        </div>
      </div>
    </fw-modal>
  </fw-panel>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordApplication from '@/components/records/RecordApplication'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    ContextualSearch,
    FilterTag,
    BlockPagination,
    LoadingPlaceholder,
    RecordApplication,
    RecycleScroller,
  },

  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    call: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    results: {
      type: Object,
      default: () => {},
    },
    peopleValidations: {
      type: Object,
      default: () => {},
    },
    activeApplicationKey: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      filterByType: 'all',
      filters: [
        // {
        //   key: 'type',
        //   label: this.$t('spaceType.label'),
        //   options: [
        //     {
        //       key: 'reset',
        //       label: this.$t('all'),
        //     },
        //     {
        //       key: 'development',
        //       label: this.$t('spaceType.development'),
        //     },
        //     {
        //       key: 'support',
        //       label: this.$t('spaceType.support'),
        //     },
        //   ],
        // },
      ],
      orderBy: [
        {
          key: 'created_at',
          label: this.$t('orderBy.createdDate'),
          type: 'date',
        },
      ],
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      searchInput: '',
      orderByValue: 'created_at',
      orderDirection: 'DESC',
      appliedFilters: [],
      applications: [],
      startPeriod: null,
      endPeriod: null,
      activeModal: null,
      managersKeys: [],
      userFilters: [],

      distribution: {
        activeModal: false,
        actualState: {},
        running: false,
        results: {},
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    canExecuteDistribution() {
      return (
        this.distribution.actualState &&
        this.distribution.actualState.applications > 0 &&
        this.distribution.actualState.managers.length
      )
    },
    isManagerOrCallManager() {
      return this.peopleValidations && this.peopleValidations.can_edit_workers
    },
  },

  watch: {
    results() {
      this.totalResults = this.results.pagination?.total_items
      this.totalPages = this.results.pagination?.total_pages
      this.page = this.results.pagination?.current_page
      this.applications = this.results.applications
      this.managersKeys = this.results.filters['manager[]'] //.managers
      console.log(this.results)
      this.filters = [
        {
          key: 'state',
          label: this.$t('status'),
          options: this.results.filters['state[]'].map(el => {
            return {
              key: el,
              label: this.$t('statusLabel.' + el),
            }
          }),
          //   options: [
        },
      ]
    },
  },

  mounted() {
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getApplications()
      this.scrollToActiveApplication()
    })
  },

  methods: {
    // Applications distribuition
    async openApplicationsDistributionModal() {
      utils.tryAndCatch(this, async () => {
        this.distribution.actualState = await this.api.getCallApplicationsDistribution(this.call.key)
        if (this.distribution.actualState.validations.can_click) {
          this.distribution.activeModal = true
        } else {
          console.log('Modal will not be opened because user does not have permission.')
        }
        console.log('Call distribution', this.distribution)
      })
    },
    async doCallApplicationsDistribution() {
      this.distribution.running = true
      utils.tryAndCatch(
        this,
        async () => {
          this.distribution.results = await this.api.doCallApplicationsDistribution(this.call.key)
          console.log('Result of call distribution execution', this.distribution.results)
          this.$emit('search')
        },
        () => {
          setTimeout(() => {
            this.distribution.running = false
          }, 1000)
        }
      )
    },
    closeDistributionModal() {
      this.distribution.activeModal = false
      // Make sure user does not see a glitch when closing the modal
      setTimeout(() => {
        this.distribution.running = false
        this.distribution.results = {}
      }, 250)
    },

    scrollToActiveApplication() {
      //scroll to active application
      if (this.activeApplicationKey) {
        const index = this.applications.findIndex(application => application.key == this.activeApplicationKey)
        //this.$refs.sidebarApplicationsList.scrollToIndex(index)
        this.$refs.applicationsList.scrollToItem(index)
      }
    },
    goToApplication(key) {
      this.$router.push({
        name: 'manage-call-application',
        params: { key: this.$route.params.key, applicationKey: key },
      })
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getApplications()
    },

    deleteUserFilter(index) {
      this.userFilters.splice(index, 1)
      this.setUrlParams()
      this.getApplications()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getApplications()
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      console.log(data)
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.userFilters = data.filterUsers

      this.$emit('searching')

      this.setUrlParams()
      this.getApplications()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getApplications()
    },

    async getApplications() {
      const maxNumber = this.maxNumberSpaces != null ? this.maxNumberSpaces : this.limit
      const query = { limit: maxNumber, page: this.page, ...utils.setFiltersQuery(this.appliedFilters) }
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      if (this.userFilters.length > 0) {
        query['manager'] = this.userFilters
      }

      this.$emit('search', query)
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getApplications()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Data de criação",
    "results": "Resultados",
    "noApplications": "Sem candidaturas a mostrar.",
    "all": "Todos",
    "orderBy": {
      "createdDate": "Data de criação"
    },
    "status": "Estado",
    "statusLabel": {
      "submitted": "Submetida",
      "in_analysis": "Em análise",
      "in_analysis_waiting": "Em análise (a aguardar resposta)",
      "complaint": "Audiência de interessados",
      "complaint_analysis": "Audiência de interessados (em análise)",
      "waiting_rejection": "Em análise (sem resposta)",
      "complaint_waiting_rejection": "Audiência de interessados (sem resposta)",
      "in_progress": "Em progresso",
      "closed": "Terminada"
    }
  },
  "en": {
    "createdDate": "Creation date",
    "results": "Results",
    "noApplications": "No applications to present.",
    "all": "All",
    "orderBy": {
      "createdDate": "Creation date"
    },
    "status": "Status",
    "statusLabel": {
      "submitted": "Submitted",
      "in_analysis": "In analysis",
      "in_analysis_waiting": "In analysis - waiting",
      "complaint": "Complaint",
      "complaint_analysis": "Complaint analysis",
      "in_progress": "In progress",
      "closed": "Closed"
    }
  }
}
</i18n>
