<template>
  <div>
    <div>
      <LoadingPlaceholder v-if="loading" />
      <div v-else-if="receipts.length">
        <fw-panel
          title="Submetidos"
          title-size="h4"
          :counter="receipts ? receipts.length : 0"
          :counter-total="totalResults"
          boxed
          custom-class="bg-white p-2"
        >
          <template v-for="item in receipts">
            <div
              :key="item.key"
              class="group p-1.5 border-b border-gray-100 flex gap-2 items-center hover:bg-gray-100 rounded-lg"
            >
              <div class="flex-1 pr-5" :class="{ 'opacity-50': item.state === 'rejected' }">
                <RecordFileEntry :file="item.files[0]" @download="downloadFile(item.files[0])">
                  <template #footer>
                    <div class="text-gray-500 text-xs">
                      <div>Submetido em {{ item.created_date | formatDateTime }} {{ item.amount }}</div>
                    </div>
                  </template>
                </RecordFileEntry>
              </div>
              <div
                class="items-center"
                :class="{ 'opacity-50': item.state === 'rejected' }"
                @click="openEditReceiptModal(item)"
              >
                <fw-tag
                  v-if="item.state === 'approved' && (item.amount || item.emission_date)"
                  type="xlight"
                  size="sm"
                  class="mr-3 gap-3 font-medium"
                >
                  <span class="text-primary">{{ item.amount | currency }}</span>
                  <span class="text-primary">{{ item.emission_date | formatDate }}</span>
                  <span v-if="item.month || item.year" class="flex items-center text-primary">
                    <span v-if="item.month">{{ item.year ? `${item.month}/` : item.month }}</span>
                    <span v-if="item.year">{{ item.year }}</span>
                  </span>
                  <span v-if="item.export_key" class="flex gap-0.5 items-center justify-center text-xs text-primary">
                    <span>DGES</span>
                    <fw-icon-checkbox-circle class="w-3 h-3" />
                  </span>
                </fw-tag>
                <fw-tag :type="receiptStates[item.state].tagType">{{
                  receiptStates[item.state].label[language]
                }}</fw-tag>
              </div>
              <div class="w-12">
                <fw-button
                  v-if="item.validations.can_edit"
                  size="xs"
                  class="group-hover:opacity-100 opacity-20"
                  type="link-muted"
                  label="Validar"
                  @click.native="openEditReceiptModal(item)"
                >
                  Editar
                </fw-button>
              </div>
            </div>
          </template>
        </fw-panel>
        <BlockPagination
          v-if="totalPages > 1 && !loading"
          :per-page="limit"
          :total="totalResults"
          :total-pages="totalPages"
          :current.sync="page"
          @page-changed="pageChanged"
        />
      </div>
      <fw-panel-info v-else empty centered>
        Ainda não foram submetidos recibos no âmbito desta candidatura.
      </fw-panel-info>
    </div>
    <fw-modal
      v-if="showEditReceiptModal"
      title="Validar recibo"
      :active.sync="showEditReceiptModal"
      boxed="sm"
      size="min"
      width="32rem"
      :loading="savingReceipt"
      @close="closeEditReceiptsModal()"
    >
      <div>
        <div class="bg-gray-200 rounded-lg p-2 mb-5">
          <RecordFileEntry :file="activeReceipt.files[0]" @download="downloadFile(activeReceipt.files[0])">
            <template #footer>
              <div class="text-gray-500 text-xs">
                <div>Submetido em {{ activeReceipt.created_date | formatDateTime }}</div>
              </div>
            </template>
          </RecordFileEntry>
        </div>
        <div class="flex flex-col gap-3">
          <div>
            <fw-label>Estado</fw-label>
            <b-select
              v-model="tmpReceipt.state"
              class="fw-select w-full"
              placeholder="Defina um estado"
              expanded
              @input="tmpReceiptDirty = true"
            >
              <option value="approved">Aceite</option>
              <option value="rejected">Rejeitado</option>
            </b-select>
          </div>
          <div v-if="tmpReceipt.state === 'approved'">
            <fw-label>Valor do recibo</fw-label>
            <NumberInput
              v-model="tmpReceipt.amount"
              placeholder="Valor em euros"
              :step="0.01"
              :maxlength="20"
              :disabled="loading"
              @input="tmpReceiptDirty = true"
            ></NumberInput>
          </div>
          <div v-if="tmpReceipt.state === 'approved'">
            <fw-label>Data de emissão do recibo</fw-label>
            <b-datepicker
              v-model="tmpReceipt.emission_date"
              :years-range="[-2, 2]"
              placeholder="Indique uma data"
              @input="tmpReceiptDirty = true"
            />
          </div>
          <div v-if="tmpReceipt.state === 'approved'">
            <fw-label>Data de effectiva do recibo (mês/ano)</fw-label>
            <div class="flex gap-5">
              <b-select
                v-model="tmpReceipt.month"
                class="fw-select w-full"
                placeholder="Defina um mês"
                :disabled="loading"
                expanded
                @input="tmpReceiptDirty = true"
              >
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </b-select>

              <b-select
                v-model="tmpReceipt.year"
                class="fw-select w-full"
                :disabled="loading"
                placeholder="Defina um ano"
                expanded
                @input="tmpReceiptDirty = true"
              >
                <option v-for="year in yearOptions" :key="year" :value="year">{{ year }}</option>
              </b-select>
            </div>
          </div>
          <div v-if="tmpReceipt.state === 'rejected'">
            <fw-label>Justificação da rejeição</fw-label>
            <fw-editor v-model="tmpReceipt.state_justification" :limit="1000" @input="tmpReceiptDirty = true" />
          </div>
        </div>
        <div v-if="activeReceipt.state_date || activeReceipt.updated_date" class="text-xs my-5 text-gray-500">
          <div v-if="activeReceipt.state_date">Estado definido em {{ activeReceipt.state_date | formatDateTime }}</div>
          <div v-if="activeReceipt.updated_date">Atualizado em {{ activeReceipt.updated_date | formatDateTime }}</div>
          <div v-if="activeReceipt.export_key != null">
            Recibo processado e incluído no mapa DGES {{ exportData.name }}
          </div>
        </div>
        <div class="flex gap-5 justify-end mt-5">
          <fw-button type="link-muted" @click.native="closeEditReceiptsModal()">Fechar</fw-button>
          <fw-button
            v-if="activeReceipt.validations.can_edit"
            :type="canSaveReceipt ? 'primary' : 'disabled'"
            :disabled="!canSaveReceipt || savingReceipt"
            :loading="savingReceipt"
            @click.native="saveReceiptMetadata()"
            >Guardar</fw-button
          >
        </div>
      </div>
    </fw-modal>
  </div>
</template>

<script>
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import RecordFileEntry from '@/fw-modules/fw-core-vue/storage/components/records/RecordFileEntry'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { APPLICATION_RECEIPTS_STATES, arrayNumberRange } from '@/utils/index.js'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    BlockPagination,
    LoadingPlaceholder,
    RecordFileEntry,
    NumberInput,
  },

  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },
    application: {
      type: Object,
      default: () => {
        return {}
      },
    },
    call: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      loading: false,
      savingReceipt: false,
      showEditReceiptModal: false,
      receipts: [],
      results: {},
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 200,
      activeReceipt: null,
      tmpReceipt: null,
      tmpReceiptDirty: false,
      receiptStates: APPLICATION_RECEIPTS_STATES,
      yearOptions: [],
    }
  },

  computed: {
    exportData() {
      return this.results.exports != null && this.tmpReceipt != null && this.tmpReceipt.export_key != null
        ? this.results.exports[this.tmpReceipt.export_key]
        : null
    },
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    canSaveReceipt() {
      return (
        !!(
          (this.tmpReceipt &&
            this.tmpReceipt.state === 'approved' &&
            this.tmpReceipt.amount > 0 &&
            this.tmpReceipt.emission_date &&
            this.tmpReceipt.year &&
            this.tmpReceipt.month) ||
          (this.tmpReceipt.state === 'rejected' &&
            this.tmpReceipt.state_justification &&
            this.tmpReceipt.state_justification.length > 7)
        ) &&
        this.tmpReceiptDirty &&
        this.activeReceipt.validations.can_edit
      )
    },
  },

  watch: {
    results() {
      this.totalResults = this.results.pagination?.total_items
      this.totalPages = this.results.pagination?.total_pages
      this.page = this.results.pagination?.current_page
      this.receipts = this.results.receipts
    },
    application() {
      this.getReceipts()
    },
  },

  mounted() {
    this.getReceipts()
  },

  methods: {
    async getReceipts() {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getApplicationReceipts(this.call.key, this.application.key)
          // We should be dealing with pagination here...
          // lets hope that noone will upload more than 200 receipts
          this.results = response
          this.receipts = response.receipts
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 250)
        }
      )
    },

    openEditReceiptModal(receipt) {
      this.showEditReceiptModal = true
      // Convert date to date object
      receipt.emission_date = receipt.emission_date ? Dates.toDate(receipt.emission_date) : null
      this.activeReceipt = receipt
      this.tmpReceipt = Object.assign({}, receipt)
      const now = new Date()
      let month = now.getMonth() + 1
      let year = now.getFullYear()
      if (this.tmpReceipt.month == null || typeof this.tmpReceipt.month === 'undefined') {
        this.tmpReceipt.month = month.toString()
      }
      if (this.tmpReceipt.year == null || typeof this.tmpReceipt.year === 'undefined') {
        this.tmpReceipt.year = year.toString()
      }
      this.yearOptions = arrayNumberRange(year - 20, year + 5, 1).reverse()
    },

    closeEditReceiptsModal() {
      this.showEditReceiptModal = false
      this.tmpReceipt = null
      this.tmpReceiptDirty = false
    },

    saveReceiptMetadata() {
      if (!this.tmpReceipt.state) {
        return
      }

      this.savingReceipt = true
      utils.tryAndCatch(
        this,
        async () => {
          let payload = {
            state: this.tmpReceipt.state,
          }

          if (this.tmpReceipt.state === 'approved') {
            payload['amount'] = this.tmpReceipt.amount
            payload['emission_date'] = this.tmpReceipt.emission_date
              ? Dates.formatDateToAPI(this.tmpReceipt.emission_date)
              : null
            payload['year'] = parseInt(this.tmpReceipt.year)
            payload['month'] = parseInt(this.tmpReceipt.month)
          } else {
            payload['state_justification'] = this.tmpReceipt.state_justification
          }
          const response = await this.api.validateReceipt(
            this.call.key,
            this.application.key,
            this.activeReceipt.key,
            payload
          )

          // Update the receipt to avoid reloading the whole list
          this.activeReceipt.state = response.receipt.state
          this.activeReceipt.state_justification = response.receipt.state_justification
          this.activeReceipt.emission_date = response.receipt.emission_date
          this.activeReceipt.amount = response.receipt.amount
          this.activeReceipt.month = response.receipt.month
          this.activeReceipt.year = response.receipt.year
          this.activeReceipt.export_key = response.receipt.export_key

          // Update validations
          this.activeReceipt.validations.can_edit = response.receipt.validations.can_edit

          this.$buefy.snackbar.open({
            message: this.$t('receiptUpdated'),
            type: 'is-success',
            duration: 5000,
          })

          setTimeout(() => {
            this.closeEditReceiptsModal()
          }, 250)
        },
        () => {
          setTimeout(() => {
            this.savingReceipt = false
          }, 250)
        }
      )
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "receiptUpdated": "Recibo atualizado"
  },
  "en": {
    "receiptUpdated": "Receipt updated"
  }
}
</i18n>
