<template>
  <SidebarBase sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="!view || view == 'dashboard'"
          :label="$t('dashboard')"
          icon="dashboard"
          @click.native="goTo('dashboard')"
        />
        <ButtonSidebar
          :active="view == 'metadata'"
          :label="$t('metadata')"
          icon="file-list"
          @click.native="goTo('metadata')"
        />
        <ButtonSidebar :active="view == 'people'" :label="$t('people')" icon="people" @click.native="goTo('people')" />
        <ButtonSidebar
          :active="view == 'applications' || view == 'application'"
          :label="$t('applications')"
          icon="survey"
          @click.native="goTo('applications')"
        />
        <ButtonSidebar
          v-if="!isWorker"
          :label="$t('reports')"
          :active="view == 'reports'"
          icon="report"
          @click.native="goTo('reports')"
        />
        <ButtonSidebar
          v-if="!isWorker"
          :label="$t('activity')"
          :active="view == 'activity'"
          icon="activity"
          @click.native="goTo('activity')"
        />
        <ButtonSidebar
          v-if="!isWorker"
          :label="$t('notifications')"
          :active="view == 'notifications'"
          icon="message-sent"
          @click.native="goTo('notifications')"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
  },

  props: {
    currentQueue: {
      type: String,
    },
    queues: {
      type: Array,
      default: () => [],
    },
    validations: {
      type: Object,
      default: () => {},
    },
    callType: {
      type: String,
      default: 'scholarship',
    },
    peopleValidations: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    callKey() {
      return this.$route.params.key
    },
    view() {
      return this.$route.meta.view ?? 'dashboard'
    },
    isWorker() {
      return (
        this.peopleValidations && !this.peopleValidations.can_edit_managers && !this.peopleValidations.can_edit_workers
      )
    },
  },

  methods: {
    goTo(routeName) {
      this.$router.push({ name: `manage-call-${routeName}`, params: { key: this.callKey } })
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "dashboard": "Dashboard",
    "activity": "Atividade",
    "people": "Pessoas",
    "queues": "Filas",
    "notifications": "Notificações",
    "settings": "Configurações",
    "metadata": "Metadados",
    "applications": "Candidaturas",
    "applicants": "Applicants",
    "calendar": "Calendarização",
    "reports": "Mapas"
  },
  "en": {
    "dashboard": "Dashboard",
    "activity": "Activity",
    "people": "People",
    "queues": "Queues",
    "notifications": "Notifications",
    "settings": "Settings",
    "metadata": "Metadata",
    "applications": "Applications",
    "applicants": "Applicants",
    "calendar": "Time schedule",
    "reports": "Reports"
  }
}
</i18n>
