<template>
  <div class="w-full h-full flex">
    <!-- Sidebar -->
    <SidebarApplicationsList
      class="w-72 2xl:w-80 h-full flex flex-col"
      :applications="applications.applications"
      :users="users"
      :active-application="application ? application.key : 'none'"
      :pagination="applications.pagination"
      @page-changed="$emit('page-changed', $event)"
    ></SidebarApplicationsList>
    <!-- Main -->
    <div
      v-if="application"
      class="flex-1 h-full border-l border-gray-200 relative flex"
      :class="{ 'blur-sm opacity-40': loading }"
    >
      <!-- Application main -->
      <div v-if="application.data" class="px-10 py-5 flex-1 2xl:w-3/4 overflow-auto">
        <fw-heading size="h3" muted>Candidatura a {{ call.prefix }}{{ call.code }}</fw-heading>
        <fw-heading size="h1">{{ application.data.name }}</fw-heading>
        <fw-panel
          v-if="application.data"
          title="Metadados"
          class="my-10 relative overflow-hidden"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEditApplication" #toolbar>
            <fw-button type="link" @click.native="isModalEditMetadataActive = true">Editar</fw-button>
          </template>
          <template #default>
            <div :class="{ 'h-96': !expandedMetadata }">
              <div class="mb-5">
                <fw-heading size="h3">Dados pessoais</fw-heading>
                <div class="mb-3">
                  <fw-label>Nome completo</fw-label>
                  <div>{{ application.data.name || $t('notDefined') }}</div>
                </div>
                <div v-if="users[application.user_key]" class="mb-3">
                  <fw-label>Email</fw-label>
                  <div>{{ users[application.user_key].email || $t('notDefined') }}</div>
                </div>
                <div class="mb-3">
                  <fw-label>Telemovel</fw-label>
                  <div>
                    {{
                      application.data.phone_country && application.data.phone_country.code
                        ? application.data.phone_country.code
                        : ''
                    }}
                    {{ application.data.phone_number || $t('notDefined') }}
                  </div>
                </div>
                <div class="mb-3">
                  <fw-label>Documento de identificação</fw-label>
                  <div>
                    Tipo:
                    {{
                      application.data.identity_type
                        ? $t(`identityTypes.${application.data.identity_type}`)
                        : $t('notDefined')
                    }}
                  </div>
                  <div>Número: {{ application.data.identity_value || $t('notDefined') }}</div>
                  <div>
                    Data de expiração:
                    <span v-if="application.data.identity_expire_date">{{
                      application.data.identity_expire_date | formatDate
                    }}</span>
                    <span v-else>{{ $t('notDefined') }}</span>
                  </div>
                </div>
                <div class="mb-3">
                  <fw-label>NIF</fw-label>
                  <div>
                    {{ application.data.vat_country_code }} {{ application.data.vat_value || $t('notDefined') }}
                  </div>
                </div>
                <div class="mb-3">
                  <fw-label>IBAN</fw-label>
                  <div>{{ application.data.iban || $t('notDefined') }}</div>
                </div>
                <div class="mb-3">
                  <fw-label>O/A requerente é um dos titulares da conta</fw-label>
                  <div>{{ application.data.is_account_holder ? 'Sim' : 'Não' }}</div>
                </div>

                <div v-if="application.data.is_account_holder == false" class="mb-3">
                  <fw-label>Titular da conta</fw-label>
                  <div>{{ application.data.account_holder || $t('notDefined') }}</div>
                </div>
              </div>
              <div class="my-5">
                <fw-heading size="h3" class="mt-5">Residência do agregado familiar</fw-heading>
                <div class="mb-3">
                  <fw-label>Morada</fw-label>
                  <div>{{ application.data.address || $t('notDefined') }}</div>
                </div>
                <div class="inline-flex gap-4">
                  <div class="mb-3">
                    <fw-label>Código Postal</fw-label>
                    <div>{{ application.data.postal_code || $t('notDefined') }}</div>
                  </div>
                  <div class="mb-3">
                    <fw-label>Localidade</fw-label>
                    <div>{{ application.data.locality || $t('notDefined') }}</div>
                  </div>
                </div>
                <div class="mb-3">
                  <fw-label>País</fw-label>
                  <div>
                    {{ application.data.country_code ? application.data.country_code : $t('notDefined') }}
                  </div>
                </div>
                <div>
                  <fw-heading size="h3" class="mt-5">Residência em tempo de aulas</fw-heading>
                  <div class="mb-3">
                    <fw-label>Morada</fw-label>
                    <div>{{ application.data.address_during_classes.address || $t('notDefined') }}</div>
                  </div>
                  <div class="inline-flex gap-4">
                    <div class="mb-3">
                      <fw-label>Código Postal</fw-label>
                      <div>{{ application.data.address_during_classes.postal_code || $t('notDefined') }}</div>
                    </div>
                    <div class="mb-3">
                      <fw-label>Localidade</fw-label>
                      <div>{{ application.data.address_during_classes.locality || $t('notDefined') }}</div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <fw-label>País</fw-label>
                    <div>
                      {{
                        application.data.address_during_classes.country_code
                          ? application.data.address_during_classes.country_code
                          : $t('notDefined')
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-5">
                <fw-heading size="h3">Informação académica</fw-heading>
                <div class="mb-3">
                  <fw-label>Número de estudante</fw-label>
                  <div>{{ application.data.academic_info.student_number || $t('notDefined') }}</div>
                </div>
                <div class="mb-3">
                  <fw-label>Curso</fw-label>
                  <div>{{ application.data.academic_info.course || $t('notDefined') }}</div>
                </div>
                <div class="mb-3">
                  <fw-label>Ciclo de Estudos</fw-label>
                  <div>{{ $t(`studyCycles.${application.data.academic_info.study_cycle}`) || $t('notDefined') }}</div>
                </div>
                <div class="mb-3">
                  <fw-label>Faculdade</fw-label>
                  <div v-if="application.data.academic_info.institution" class="uppercase">
                    {{ application.data.academic_info.institution }}
                  </div>
                  <div v-else>{{ $t('notDefined') }}</div>
                </div>
              </div>

              <div class="my-5 hidden">
                <fw-heading size="h3">Declaração de honra</fw-heading>
                <div>
                  <fw-label>
                    Eu, acima identificado, venho requerer a atribuição do apoio extraordinário ao alojamento previsto
                    no Despacho n.º 3163/2023, de 9 de março
                  </fw-label>
                  <div>{{ application.data.accept_extraordinary_support_for_housing ? 'Sim' : 'Não' }}</div>
                </div>
                <div>
                  <fw-label>
                    Para o efeito, anexo a este requerimento a documentação obrigatória necessária ao tratamento do
                    pedido.
                  </fw-label>
                  <div>{{ application.data.entered_required_docs ? 'Sim' : 'Não' }}</div>
                </div>
                <div>
                  <fw-label>
                    Mais declaro que tomei conhecimento e que autorizo os termos do tratamento dos meus dados pessoais
                    no âmbito deste pedido.
                  </fw-label>
                  <div>{{ application.data.accept_data_treatment ? 'Sim' : 'Não' }}</div>
                </div>
              </div>
              <div class="mt-5">
                <fw-heading size="h3" class="mb-2">{{ $t('files') }}</fw-heading>
                <!-- File section:
                household_fiscal_address
                family_allowance
                permanent_residence
                non_existence_of_debts
                payment_receipts
                iban
                TODO: add file section info to files list
              -->
                <div v-if="application.files && application.files.length > 0">
                  <RecordFileEntry
                    v-for="file in application.files"
                    :key="file.key"
                    :can-edit="false"
                    :allow-classified="false"
                    :file="file"
                    :can-remove="false"
                    @download="downloadFile(file)"
                  >
                    <template #secondline>
                      <div class="text-gray-500 text-sm -mt-0.5">{{ $t(`fileTypes.${file.section}`) }}</div>
                    </template>
                  </RecordFileEntry>
                </div>
                <div v-else class="text-gray-500 text-sm">{{ $t('noFiles') }}</div>
              </div>
            </div>
            <div
              class="flex items-end justify-center"
              :class="{
                'h-44 absolute bottom-0 left-0 w-full from-white to-transparent bg-gradient-to-t border-b border-dashed': !expandedMetadata,
                'relative -mb-5': expandedMetadata,
              }"
            >
              <div class="opacity-70 hover:opacity-100" :class="{ 'p-3': !expandedMetadata }">
                <fw-button type="link" size="xs" @click.native="expandedMetadata = !expandedMetadata">
                  <span>{{ expandedMetadata ? 'Reduzir' : 'Expandir' }} painel</span>
                  <fw-icon-chevron-up v-if="expandedMetadata" class="w-5 h-5" />
                  <fw-icon-chevron-down v-else class="w-5 h-5" />
                </fw-button>
              </div>
            </div>
          </template>
        </fw-panel>
        <fw-panel title="Gestão" class="my-10">
          <b-tabs v-model="activeManagementTab" :animated="false" :destroy-on-hide="true">
            <b-tab-item label="Metadados" value="metadata">
              <div v-if="application.additional_data" class="p-5 bg-white rounded-lg flex flex-col gap-5">
                <div class="flex flex-col gap-2">
                  <div class="flex gap-5">
                    <div>
                      <fw-label>Escalão de Abono</fw-label>
                      <b-select
                        v-model="application.additional_data.tax_tier"
                        class="fw-select w-full"
                        placeholder="Indique o escalão de abono"
                        :disabled="!canEditApplication"
                        @input="dirtyData['additional_data'] = true"
                      >
                        <option value="1">Escalão 1</option>
                        <option value="2">Escalão 2</option>
                        <option value="3">Escalão 3</option>
                      </b-select>
                    </div>
                    <div>
                      <fw-label>Tipo de alojamento</fw-label>
                      <b-select
                        v-model="application.additional_data.accommodation_type"
                        class="fw-select w-full"
                        :disabled="!canEditApplication"
                        placeholder="Indique o tipo de alojamento"
                        @input="dirtyData['additional_data'] = true"
                      >
                        <option value="private">Privado</option>
                        <option value="residence">Residência</option>
                      </b-select>
                    </div>
                  </div>
                  <div class="flex gap-5">
                    <div>
                      <fw-label>Código IES</fw-label>
                      <TextInput
                        v-model="application.additional_data.ies_code"
                        :disabled="!canEditApplication"
                        :minlength="2"
                        :maxlength="50"
                        class="w-44"
                        @input="dirtyData['additional_data'] = true"
                      ></TextInput>
                    </div>
                    <div class="flex-1">
                      <fw-label>IES</fw-label>
                      <TextInput
                        v-model="application.additional_data.ies"
                        :disabled="!canEditApplication"
                        :minlength="5"
                        :maxlength="150"
                        @input="dirtyData['additional_data'] = true"
                      ></TextInput>
                    </div>
                  </div>
                  <div class="flex gap-5">
                    <div>
                      <fw-label>Código do Curso</fw-label>
                      <TextInput
                        v-model="application.additional_data.course_code"
                        :disabled="!canEditApplication"
                        :minlength="5"
                        :maxlength="50"
                        class="w-44"
                        @input="dirtyData['additional_data'] = true"
                      ></TextInput>
                    </div>
                    <div class="flex-1">
                      <fw-label>Tipo de curso</fw-label>
                      <TextInput
                        v-model="application.additional_data.course_type"
                        :disabled="!canEditApplication"
                        :minlength="5"
                        :maxlength="100"
                        @input="dirtyData['additional_data'] = true"
                      ></TextInput>
                    </div>
                  </div>
                  <div>
                    <fw-label>Curso</fw-label>
                    <TextInput
                      v-model="application.additional_data.course"
                      :disabled="!canEditApplication"
                      :minlength="5"
                      :maxlength="250"
                      @input="dirtyData['additional_data'] = true"
                    ></TextInput>
                  </div>
                </div>
                <div v-if="canEditApplication">
                  <fw-button
                    :disabled="!dirtyData['additional_data']"
                    :loading="savingData['additional_data']"
                    type="primary"
                    :class="{ 'opacity-30': !dirtyData['additional_data'] }"
                    @click.native="updateApplication('additional_data')"
                    >Guardar</fw-button
                  >
                </div>
              </div>
            </b-tab-item>
            <b-tab-item
              v-if="!application.decision || application.decision === 'rejected'"
              label="Motivos de rejeição"
              value="rejected-reasons"
            >
              <div class="bg-white p-5">
                <div class="my-3">
                  <div v-for="(reasonTitle, reasonKey) in availableRejectedReasons" :key="reasonKey" class="my-1">
                    <b-checkbox
                      v-model="application.rejected_reasons"
                      :disabled="savingData['rejected_reasons'] || !canEditApplication"
                      :native-value="reasonKey"
                    >
                      {{ reasonTitle }}
                    </b-checkbox>
                  </div>
                  <div v-if="canEditApplication" class="mt-3">
                    <fw-button
                      type="primary"
                      :loading="savingData['rejected_reasons']"
                      @click.native="updateApplication('rejected_reasons')"
                      >Guardar</fw-button
                    >
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item
              v-if="application.decision && application.decision === 'accepted'"
              :label="$t('receipts')"
              value="receipts"
            >
              <PanelManageApplicationReceipts :call="call" :application="application" :checks="{}" />
            </b-tab-item>
          </b-tabs>
        </fw-panel>
        <fw-panel class="mt-10" title="Atividade">
          <b-tabs v-model="activeTab" :animated="false" @input="getTabData">
            <b-tab-item :label="$t('messages')" value="messages">
              <PanelMessages
                :users="messagesUsers"
                :messages="messages"
                :pagination="paginationMessages"
                :loading="loadingMessages"
                :show-public-private-toggle="canSendMessages"
                :force-private-message="!canSendPublicMessages"
                :force-hide-private-messages="false"
                :can-add-message="canSendMessages"
                :default-add-message-is-private="true"
                :copy-files-label="'Copiar ficheiros para recibos'"
                :allow-copy-files="allowCopyFiles"
                @page-changed="messagesPageChanged"
                @add-message="addMessage"
                @update-message="updateMessage"
                @delete-message="deleteMessage"
                @copy-files="copyMessageFiles"
              />
            </b-tab-item>
            <b-tab-item :label="$t('logs')" value="logs">
              <PanelApplicationActivity
                :activity="logs"
                :users="activityUsers"
                :pagination="paginationActivity"
                :loading="loadingActivity"
                class="bg-white rounded-xl"
                @page-changed="activityPageChanged"
              ></PanelApplicationActivity>
            </b-tab-item>
          </b-tabs>
        </fw-panel>
      </div>
      <!-- Application sidebar -->
      <div class="p-5 w-72 2xl:w-80 flex flex-col gap-5">
        <div>
          <fw-label>Estado da candidatura</fw-label>
          <div>
            <fw-tag
              v-if="applicationStates[application.state]"
              expanded
              size="lg"
              :type="applicationStates[application.state].color"
              >{{ applicationStates[application.state].label[language] }}</fw-tag
            >
          </div>
          <div v-if="isSubStateActive" class="py-1.5 font-medium">
            <div v-if="application.state === 'in_analysis_waiting'" class="text-yellow-500">
              <div>A aguardar resposta.</div>
            </div>
            <div
              v-if="
                application.state === 'complaint' &&
                  application.answer_deadline &&
                  application.answer_deadline.remaining_time > 0
              "
              class="text-sm text-gray-500"
            >
              <div>Até {{ application.answer_deadline.date | formatDateTime }}.</div>
            </div>
            <div v-if="application.state === 'complaint_analysis'" class="text-yellow-500 text-sm">
              <div>Foi submetida pronúncia por parte do requerente a qual aguarda análise.</div>
            </div>
          </div>
        </div>

        <div v-if="application.decision || validations.can_accept || validations.can_reject" class="border-t pt-2">
          <fw-label>Decisão</fw-label>
          <div
            v-if="application.decision"
            class="mb-4"
            :class="{
              'text-primary': application.decision === 'accepted' && application.state !== 'closed',
            }"
          >
            <div v-if="application.decision === 'accepted'" class="text-xl font-semibold text-primary">
              Candidatura aceite
            </div>
            <div v-else class="text-xl font-semibold">Candidatura rejeitada</div>
            <div class="text-sm font-medium">{{ application.decision_date | formatDateTime }}</div>
          </div>
          <div v-if="validations.can_accept || validations.can_reject" class="grid grid-cols-2 gap-5">
            <div v-if="application.decision">
              <fw-button
                type="border-light"
                expanded
                :disabled="
                  (application.decision === 'accepted' && hasRejectedReasons) ||
                    (application.decision === 'rejected' && !hasRejectedReasons)
                "
                @click.native="
                  application.decision === 'rejected' ? openRejectMessageModal() : openAcceptMessageModal()
                "
                >Confirmar decisão</fw-button
              >
            </div>
            <div v-if="!application.decision || application.decision === 'rejected'">
              <fw-button
                :type="application.decision ? 'border-light' : 'border-primary'"
                expanded
                class="h-full px-4"
                :disabled="!validations.can_accept || hasRejectedReasons"
                @click.native="openAcceptMessageModal"
                >{{ application.decision ? 'Alterar decisão' : 'Aceitar' }}</fw-button
              >
            </div>
            <div v-if="!application.decision || application.decision === 'accepted'">
              <fw-button
                :type="application.decision ? 'border-light' : 'border-danger'"
                expanded
                class="h-full px-4"
                :disabled="!validations.can_reject || !hasRejectedReasons"
                @click.native="openRejectMessageModal"
                >{{ application.decision ? 'Alterar decisão' : 'Rejeitar' }}</fw-button
              >
            </div>
          </div>
        </div>

        <div v-if="users[application.user_key]" class="border-t pt-2">
          <fw-label>{{ $t('user') }}</fw-label>
          <Person no-style :person="users[application.user_key]" />
        </div>

        <div class="border-t pt-2">
          <div class="flex gap-2 justify-between">
            <div>
              <fw-label>{{ $t('assignees') }}</fw-label>
            </div>
            <div>
              <fw-button v-if="canEditAssignees" type="link" @click.native="chooseAssignees = true">{{
                $t('addAssignees')
              }}</fw-button>
            </div>
          </div>
          <div v-if="application.managers && application.managers.length">
            <div v-for="user_key in application.managers" :key="user_key" class="flex gap-2 px-2 py-1 items-center">
              <div class="flex-1 flex gap-3 items-center">
                <fw-avatar
                  v-if="assigneedUsers[user_key]"
                  size="xs"
                  :user="assigneedUsers[user_key]"
                  class="flex-shrink-0"
                />
                <v-clamp v-if="assigneedUsers[user_key]" class="font-semibold text-sm" autoresize :max-lines="1">
                  {{ assigneedUsers[user_key].full_name }}
                </v-clamp>
              </div>
              <div v-if="canEditAssignees" class="flex-shrink-0 opacity-70 hover:opacity-100">
                <fw-button type="xlight" label="Remover" @click.native="deleteAssignee(user_key)">
                  <fw-icon-close-circle class="h-5 w-5" />
                </fw-button>
              </div>
            </div>
          </div>
          <div v-else class="text-gray-500 text-sm">{{ $t('notDefined') }}.</div>
        </div>

        <div class="flex flex-col gap-3 text-sm border-t pt-3 text-gray-600">
          <div>
            <fw-label marginless>Chave da candidatura</fw-label>
            <div>{{ application.key }}</div>
          </div>
          <div>
            <fw-label marginless>Data de criação</fw-label>
            <div>{{ application.created_date | formatDateTime }}</div>
          </div>
          <div>
            <fw-label marginless>Data de submissão</fw-label>
            <div class="flex items-center gap-1">
              <span>{{ application.submitted_date | formatDateTime }}</span>
              <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
            </div>
          </div>
          <div v-if="application.canceled_date">
            <fw-label marginless>{{ $t('deletedAt') }}</fw-label>
            <div>{{ application.canceled_date | formatDateTime }}</div>
          </div>

          <div v-if="application.closed_date">
            <fw-label marginless>{{ $t('closedAt') }}</fw-label>
            <div>{{ application.closed_date | formatDateTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <fw-modal :active.sync="chooseAssignees" size="auto" boxed="xs" @close="closeModalChooseAssignees">
      <ModalChooseAssignees
        v-if="chooseAssignees"
        :managers="application.managers"
        :application-key="application.key"
        :call-key="call.key"
        :users="assigneedUsers"
        :available-users="assigneesList"
        @close="closeModalChooseAssignees"
        @add="addNewAssignees"
      ></ModalChooseAssignees>
    </fw-modal>
    <fw-modal
      :active.sync="isModalAcceptMessageActive"
      boxed="sm"
      size="3xl"
      width="42rem"
      @close="closeAddAcceptMessageModal"
    >
      <ModalMessage
        v-if="isModalAcceptMessageActive"
        title="Aceitar candidatura"
        description="Para aceitar, por favor, escreva uma mensagem personalizada ao candidato(a)."
        instructions="Após a aceitação, o candidato(a) será notificado(a) por email da decisão e poderá consultar esta mensagem na aplicação."
        :message="acceptMessage"
        :ignore-confirm-public-message="true"
        :allow-files="false"
        @close="closeAddAcceptMessageModal"
        @save="acceptApplication"
      />
    </fw-modal>
    <fw-modal
      :active.sync="isModalRejectMessageActive"
      boxed="sm"
      size="3xl"
      width="42rem"
      @close="closeAddRejectMessageModal"
    >
      <ModalMessage
        v-if="isModalRejectMessageActive"
        title="Rejeitar candidatura"
        description="Se desejar, edite a mensagem com a fundamentação da rejeição, que será visualizada pelo candidato(a) na aplicação."
        instructions="Nota: A edição do texto da mensagem não irá alterar os motivos de rejeição definidos para esta candidatura."
        :message="rejectMessage"
        :ignore-confirm-public-message="true"
        :allow-files="false"
        @close="closeAddRejectMessageModal"
        @save="rejectApplication"
      />
    </fw-modal>
    <fw-modal
      v-if="isModalEditMetadataActive"
      :active.sync="isModalEditMetadataActive"
      title="Editar candidatura"
      :loading="savingData['metadata']"
      boxed="sm"
      size="3xl"
      width="56rem"
      @close="closeEditMetadataModal"
    >
      <PanelManageApplicationMetadata
        :call="call"
        :application="application"
        @save="saveApplication($event)"
        @close="closeEditMetadataModal"
        @download-file="downloadFile(file)"
      />
    </fw-modal>
    <fw-modal
      v-if="showCopyFilesModal"
      :active.sync="showCopyFilesModal"
      :loading="savingData['copyfiles']"
      paddingless
      size="3xl"
      width="56rem"
      @close="closeCopyFilesModal"
    >
      <ChooseFilesModal
        :files="messageToCopyFilesFrom.files"
        :searchable="false"
        :loading="false"
        :allow-upload="false"
        :title="'Copiar ficheiros para recibo'"
        :confirm-button-text="'Copiar ficheiros'"
        :confirm-disabled="savingData['copyfiles']"
        @confirm="copyFilesToReceipt($event)"
        @close="closeCopyFilesModal"
      />
    </fw-modal>
  </div>
</template>

<script>
import PanelApplicationActivity from '@/components/panels/manage/PanelApplicationActivity'
import PanelManageApplicationMetadata from '@/components/panels/manage/PanelManageApplicationMetadata'
import PanelManageApplicationReceipts from '@/components/panels/manage/PanelManageApplicationReceipts'
import ModalMessage from '@/fw-modules/fw-core-vue/ui/components/modals/ModalMessage'
import ModalChooseAssignees from '@/components/modals/ModalChooseAssignees'
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { APPLICATION_STATES, APPLICATION_REJECTED_REASONS } from '@/utils/index.js'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import SidebarApplicationsList from '@/components/sidebars/SidebarApplicationsList'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ChooseFilesModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChooseFilesModal'

export default {
  components: {
    PanelApplicationActivity,
    PanelMessages,
    ModalChooseAssignees,
    RecordFileEntry,
    PanelManageApplicationReceipts,
    SidebarApplicationsList,
    Person,
    TextInput,
    ModalMessage,
    PanelManageApplicationMetadata,
    ChooseFilesModal,
  },

  props: {
    applications: {
      type: Object,
      default: () => {},
    },
    application: {
      type: Object,
      default: () => {},
    },
    call: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    validations: {
      type: Object,
      default: () => {},
    },
    peopleValidations: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    allowCopyFiles: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const emptyMessage = { description: '', is_private: false, files: [] }
    return {
      loadingMessages: false,
      loadingActivity: false,
      savingData: {
        metadata: false,
        additional_data: false,
        rejected_reasons: false,
        copyfiles: false,
      },
      dirtyData: {
        additional_data: false,
        rejected_reasons: false,
      },
      expandedMetadata: ['draft', 'submitted'].includes(this.application.state),
      activeTab: 'messages',
      activeManagementTab: 'metadata',
      availableAssignees: [],
      assigneedUsers: {},
      activityUsers: {},
      messagesUsers: {},
      userAgents: {},
      chooseAssignees: false,
      logs: [],
      messages: [],
      canSendMessages: false,
      canSendPublicMessages: false,
      subStates: ['in_analysis_waiting', 'complaint', 'complaint_analysis'],
      applicationStates: APPLICATION_STATES['scholarship'],
      availableRejectedReasons: APPLICATION_REJECTED_REASONS,
      emptyMessage: emptyMessage,
      rejectMessage: emptyMessage,
      acceptMessage: emptyMessage,
      isModalRejectMessageActive: false,
      isModalAcceptMessageActive: false,
      isModalEditMetadataActive: false,
      paginationActivity: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      paginationMessages: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      //Files from a message to copy to receipts
      messageToCopyFilesFrom: null,
    }
  },

  computed: {
    showCopyFilesModal() {
      return this.messageToCopyFilesFrom != null
    },
    isMobile() {
      return window.innerWidth < 640
    },
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    availableStatus() {
      return ['in_analysis', 'complaints', 'in_progress', 'closed'].filter(el => el != this.application.state)
    },
    assigneesList() {
      let managers = Array.isArray(this.application.managers) ? this.application.managers : []
      return this.availableAssignees.filter(el => !managers.includes(el)).map(user_key => this.assigneedUsers[user_key])
    },
    callKey() {
      return this.$route.params.key
    },
    applicationKey() {
      return this.$route.params.applicationKey
    },
    isSubStateActive() {
      return this.application && this.subStates.includes(this.application.state)
    },
    hasRejectedReasons() {
      return this.application.rejected_reasons && this.application.rejected_reasons.length > 0
    },
    canEditAssignees() {
      return (
        this.peopleValidations && (this.peopleValidations.can_edit_managers || this.peopleValidations.can_edit_workers)
      )
    },
    canEditApplication() {
      return this.validations && this.validations.can_edit
    },
  },

  watch: {
    applicationKey(loadAppkey) {
      if (loadAppkey !== this.application.key) this.loadInitialData()
    },
  },

  async mounted() {
    this.loadInitialData()
  },

  methods: {
    async copyFilesToReceipt(files) {
      console.log('copyFilesToReceipt', files)
      this.savingData['copyfiles'] = true
      try {
        const response = await this.api.copyFilesFromMessageToReceipts(
          this.callKey,
          this.applicationKey,
          this.messageToCopyFilesFrom.key,
          {
            files: files.map(el => el.key),
          }
        )
        /*this.$buefy.toast.open({
          duration: 3000,
          message: `Ficheiros copiados com sucesso!`,
          position: 'is-top',
        })*/
        this.$buefy.snackbar.open({
          message: 'Ficheiros copiados com sucesso!',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000,
        })
        this.savingData['copyfiles'] = false
        console.log('copyFilesToReceipt :>> ', response)
        this.closeCopyFilesModal()
      } catch (error) {
        console.log('Error deleteApplicationManagers', error)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })
        this.savingData['copyfiles'] = false
      }
    },
    closeCopyFilesModal() {
      this.messageToCopyFilesFrom = null
    },
    copyMessageFiles(message) {
      console.log('copyMessageFiles', message.files)
      this.messageToCopyFilesFrom = message
    },
    async saveApplication(application = null) {
      this.savingData['metadata'] = true

      console.log('Got application: ', application)

      let tmpApplication = JSON.parse(JSON.stringify({ data: application.data, files: application.files }))
      console.log('SAVING ', tmpApplication)
      // Parse date
      tmpApplication.data.identity_expire_date = tmpApplication.data.identity_expire_date
        ? Dates.formatDateToAPI(tmpApplication.data.identity_expire_date)
        : null

      // Parse IBAN (just in case)
      if (application.data.iban && !tmpApplication.data.iban.startsWith('PT50')) {
        tmpApplication.data.iban = 'PT50' + tmpApplication.data.iban.replace(/\s/g, '')
      }

      /*let files = []
      Object.values(tmpApplication.files).forEach(element => {
        files = [...files, ...element]
      })
      tmpApplication.files = files*/

      console.log('Save application: ', tmpApplication)

      try {
        const response = await this.api.updateApplication(this.callKey, this.applicationKey, tmpApplication)
        console.log('Got response: ', response)
        let application = response.application

        //TODO: COMMENTED TO AVOID PROP CHANGE!!!
        //if (response.validations) this.validations = response.validations

        this.setApplicationData(application)

        // Add more info when we save data
        var msg = 'Os dados foram guardados.'
        if (this.formWithErrors) {
          msg = 'Os dados foram guardados mas existem erros no formulário.'
        }

        this.$buefy.snackbar.open({
          message: msg,
          type: 'is-white',
          position: 'is-bottom-right',
          indefinite: false,
          duration: 2000,
          queue: false,
        })

        this.closeEditMetadataModal()
      } catch (error) {
        console.error(error)
        const errorKey = utils.errors(error).getKey()
        this.$buefy.dialog.alert({
          title: 'Ocorreu um erro',
          message: `<div class="mb-3">Ocorreu um erro não esperado. Por favor, tente novamente mais tarde.
          Caso o problema persista, por favor, contacte a nossa equipa de suporte.</div>
          <div class="text-sm opacity-80">Error key: ${errorKey}</div>`,
          type: 'is-danger',
        })
      }

      this.savingData['metadata'] = false
    },

    setApplicationData(application) {
      if (application.data) {
        // Country (main address)
        application.data.country_code = application.data.country_code || 'PT'

        application.data.phone_country_code = application.data.phone_country_code || 'PT'
        if (application.data.phone_number) {
          application.data.phone_number = Number(application.data.phone_number)
        }

        // Vat number (convert to number)
        application.data.vat_value = application.data.vat_value ? Number(application.data.vat_value) : null

        // Account holder (convert to boolean)
        application.data.is_account_holder = Boolean(application.data.is_account_holder)

        // Address during_classes
        if (application.data.address_during_classes) {
          application.data.address_during_classes.country_code =
            application.data.address_during_classes.country_code || 'PT'
          application.data.address_during_classes.phone_country_code =
            application.data.address_during_classes.phone_country_code || 'PT'
          if (application.data.address_during_classes.phone_number) {
            application.data.address_during_classes.phone_number = Number(
              application.data.address_during_classes.phone_number
            )
          }
        }

        // Parse IBAN (just in case)
        if (application.data.iban && application.data.iban.startsWith('PT50')) {
          application.data.iban = application.data.iban.replace('PT50', '')
        }

        application.data.identity_expire_date = application.data.identity_expire_date
          ? Dates.build(application.data.identity_expire_date).toDate()
          : null

        // Force data

        if (!application.data.address_during_classes) {
          application.data.address_during_classes = {
            address: null,
            postal_code: null,
            locality: null,
            country_code: 'PT',
            phone_number: null,
            phone_country_code: 'PT',
          }
        }

        if (!application.data.academic_info) {
          application.data.academic_info = {
            student_number: null,
            course: null,
            institution: null,
            study_cycle: null,
          }
        }

        // Number
        /*if (this.user.number) {
          application.data.academic_info.student_number = this.user.number
        }*/

        // VAT country (PT)
        application.data.vat_country_code = 'PT'
      }

      // Application data (empty)
      else {
        application.data = {
          name: '',
          address: null,
          postal_code: null,
          locality: null,
          country_code: 'PT',
          phone_number: null,
          phone_country_code: 'PT',
          identity_type: null,
          identity_value: null,
          identity_expire_Date: null,
          vat_value: null,
          vat_country_code: 'PT',
          iban: null,
          is_account_holder: null,
          account_holder: null,
          academic_info: {
            student_number: '', //this.user.number,
            course: null,
            institution: null,
            study_cycle: null,
          },
          address_during_classes: {
            address: null,
            postal_code: null,
            locality: null,
            country_code: null,
          },
        }
      }

      /*const files = {
        household_fiscal_address: [],
        family_allowance: [],
        permanent_residence: [],
        non_existence_of_debts: [],
        payment_receipts: [],
        iban: [],
      }

      if (application.files && application.files.length) {
        application.files.forEach(file => {
          if (file.section) files[file.section].push(file)
        })
      }

      application.files = files*/

      console.log('application.files :>> ', application.files)
      this.$emit('updated-application', {
        application: application,
        validations: this.validations,
      })
      //this.application = application
    },

    loadInitialData() {
      this.getAssigneesList()
      this.getTabData(this.activeTab)
    },

    closeModalChooseAssignees() {
      this.chooseAssignees = false
    },

    closeEditMetadataModal() {
      this.isModalEditMetadataActive = false
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    async deleteAssignee(userKey) {
      console.log('deleteAssignee :>> ', userKey)
      try {
        const response = await this.api.deleteApplicationManagers(this.callKey, this.applicationKey, [userKey])
        console.log('deleteApplicationManagers :>> ', response)
        this.$emit('update-application-managers', response.application)
      } catch (error) {
        console.log('Error deleteApplicationManagers', error)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })
      }
    },

    async getAssigneesList() {
      try {
        //const response = await this.api.getApplicationManagers(this.callKey, this.applicationKey)
        const response = await this.api.getApplicationManagers(this.callKey, this.applicationKey)
        console.log('getApplicationManagers :>> ', response)
        this.availableAssignees = Object.keys(response.managers)
        this.assigneedUsers = response.users
      } catch (error) {
        console.log('Error getApplicationManagers', error)
      }
    },

    async addNewAssignees(newManagersKeys) {
      console.log('addNewAssignees :>> ', newManagersKeys)
      try {
        const response = await this.api.createApplicationManagers(this.callKey, this.applicationKey, newManagersKeys)
        console.log('createApplicationManagers :>> ', response)
        this.$emit('update-application-managers', response.application)
        //update managers
      } catch (error) {
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })
        console.log('Error createApplicationManagers', error)
      }
    },

    async changeStatus(newStatus) {
      console.log('changeStatus :>> ', newStatus)
      this.loading = true
      try {
        const result = await this.api.changeApplicationStatus(this.callKey, this.applicationKey, newStatus)
        console.log('result :>> ', result)
        this.$emit('updated-application', result)
      } catch (error) {
        console.error('Error changeStatus', error)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })

        this.loadingError = true
      }
      this.loading = false
    },

    async getTabData(tab) {
      console.log('getTabData :>> ', tab)
      if (tab == 'messages') this.getMessages()
      else if (tab == 'logs') this.getActivity()
    },

    async getMessages() {
      this.loadingMessages = true

      try {
        console.log('getMessages params :>> ', this.paginationMessages)
        const response = await this.api.getApplicationMessages(this.callKey, this.applicationKey, {
          page: this.paginationMessages.page,
          limit: this.paginationMessages.limit,
        })
        console.log('getMessages :>> ', response)
        this.canSendMessages = response.validations.can_send_message
        this.canSendPublicMessages = response.validations.can_send_public_message
        this.messages = response.messages
        this.messagesUsers = { ...this.messagesUsers, ...response.users }
        this.paginationMessages = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getMessages :>> ', e)
      }

      this.loadingMessages = false
    },

    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.paginationMessages.page = page
      this.getMessages()
    },

    async getActivity() {
      this.loadingActivity = true

      try {
        console.log('getApplicationActivity params :>> ', this.paginationActivity)
        const response = await this.api.getApplicationActivity(this.callKey, this.applicationKey, {
          page: this.paginationActivity.page,
          limit: this.paginationActivity.limit,
        })
        console.log('getApplicationActivity :>> ', response)
        this.logs = response.logs
        this.activityUsers = { ...this.activityUsers, ...response.users }
        this.paginationActivity = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getApplicationActivity :>> ', e)
      }

      this.loadingActivity = false
    },

    activityPageChanged(page) {
      console.log('activityPageChanged :>> ', page)
      this.paginationActivity.page = page
      this.getActivity()
    },

    async addMessage(messageData, type = null) {
      console.log('messageData :>> ', messageData)
      try {
        const response = await this.api.addApplicationMessages(this.callKey, this.applicationKey, messageData)
        console.log('response :>> ', response)
        console.log('addedMessage :>> ', response)
        this.messages.unshift(response.message)
        this.$emit('added-message', response)

        if (type === 'rejected') {
          this.closeAddRejectMessageModal()
        }
      } catch (error) {
        this.savingMessageError = true
        const errorKey = utils.errors(error).getKey()
        console.log('error :>> ', error)
        this.$buefy.dialog.alert({
          title: 'Ocorreu um erro',
          message: `Não foi possível guardar a mensagem: ${errorKey}`,
          type: 'is-danger',
          duration: 4000,
        })
      }
    },

    async updateMessage(messageData) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.updateApplicationMessage(
          this.callKey,
          this.applicationKey,
          messageData.key,
          messageData
        )
        console.log('updatedMessage :>> ', response)
        let index = this.messages.findIndex(el => el.key == response.key)
        if (index > -1) this.$set(this.messages, index, response)
      })
    },

    async deleteMessage(message) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteApplicationMessage(this.callKey, this.applicationKey, message.key)
        console.log('deleteApplicationMessage :>> ', response)
        this.messages = this.messages.filter(el => el.key != message.key)
      })
    },

    // Save application
    async updateApplication(type) {
      let metaToUpdate = {}
      metaToUpdate[type] = this.application[type]
      this.savingData[type] = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateApplication(this.callKey, this.applicationKey, metaToUpdate)
          console.log('updateApplication :>> ', response)
          this.$emit('updated-application', response)
          setTimeout(() => {
            this.$buefy.snackbar.open({
              message: 'Dados guardados com sucesso',
              type: 'is-success',
              queue: false,
              position: 'is-bottom-right',
              duration: 2000,
            })
          }, 500)
        },
        () => {
          setTimeout(() => {
            this.savingData[type] = false
            this.dirtyData[type] = false
          }, 100)
        }
      )
    },

    // Grab custom reject message and take action
    rejectApplication(messageData) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Decisão da candidatura',
        message:
          Array.isArray(this.application.rejected_reasons) && this.application.rejected_reasons.includes('13')
            ? 'Tem a certeza que pretende rejeitar a candidatura? Tratando-se de uma candidatura duplicada, a aplicação irá notificar o requerente da decisão e terminar o processo de candidatura.'
            : this.application.decision === 'rejected'
            ? 'Tem a certeza que pretende confirmar a decisão de rejeição?'
            : 'Tem a certeza que pretende rejeitar a candidatura? A aplicação irá notificar o requerente da decisão e iniciar o período de audiência de interessados.',
        onConfirm: async () => {
          utils.tryAndCatch(this, async () => {
            const response = await this.api.updateApplication(this.callKey, this.applicationKey, {
              state: 'rejected',
              message: messageData.description,
            })
            this.$emit('added-decision', response)

            // Add custom message
            // messageData['from_decision'] = true
            // this.addMessage(messageData, 'rejected')
            this.closeAddRejectMessageModal()
            // Reload messages
            this.getMessages()
          })
        },
        confirmText: 'Rejeitar candidatura',
        cancelText: 'Cancelar',
      })
    },

    acceptApplication(messageData) {
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        title: 'Decisão da candidatura',
        message: 'Tem a certeza que pretende aceitar a candidatura? O requerente será notificado da decisão final.',
        onConfirm: async () => {
          utils.tryAndCatch(this, async () => {
            const response = await this.api.updateApplication(this.callKey, this.applicationKey, {
              state: 'accepted',
              message: messageData.description,
            })
            this.$emit('added-decision', response)
            this.closeAddAcceptMessageModal()

            // Add custom message
            //messageData['from_decision'] = true
            //this.addMessage(messageData, 'accepted')
            // Reload messages
            this.getMessages()
          })
        },
        confirmText: 'Aceitar candidatura',
        cancelText: 'Cancelar',
      })
    },

    closeAddRejectMessageModal() {
      this.isModalRejectMessageActive = false
      this.rejectMessage = this.getEmptyMessage()
    },

    closeAddAcceptMessageModal() {
      this.isModalAcceptMessageActive = false
      this.acceptMessage = this.getEmptyMessage()
    },

    openRejectMessageModal() {
      this.isModalRejectMessageActive = true

      // Build html for content of reject message
      if (this.hasRejectedReasons) {
        let reasonsHtml = ''
        for (const reasonKey of this.application.rejected_reasons) {
          reasonsHtml += `<li>${this.availableRejectedReasons[reasonKey]};</li>`
        }

        this.rejectMessage.description = `
          <p>Informamos que a sua candidatura foi rejeitada pelo(s) seguinte(s) motivo(s):</p>
          <ul>${reasonsHtml}</ul>`
      }
    },

    openAcceptMessageModal() {
      this.isModalAcceptMessageActive = true
    },

    openModal(type, message) {
      console.log('openModal :>> ', type, message)
      this.activeMessage = message
      this.activeModal = type
    },

    getEmptyMessage() {
      return {
        description: '',
        is_private: false,
        files: [],
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "receipts": "Recibos",
    "deletedAt": "Eliminado em",
    "has_different_address":  "Morada diferente",
    "fileTypes": {
      "household_fiscal_address": "Comprovativo de morada",
      "family_allowance": "Comprovativo de atribuição de abono de família",
      "permanent_residence": "Comprovativo de residência permanente",
      "non_existence_of_debts": "Comprovativo de não existência de dívidas",
      "payment_receipts": "Comprovativo de pagamento",
      "iban": "Comprovativo de IBAN"
    },
    "identityTypes": {
      "cc": "Cartão de Cidadão",
      "passport": "Passaporte",
      "residence_permit": "Título de residência"
    },
    "studyCycles": {
      "1st": "1º Ciclo - Licenciatura",
      "2nd": "2º Ciclo - Mestrado",
      "1st-2nd": "1º e 2º Ciclo - Mestrado Integrado",
      "3rd": "3º Ciclo - Doutoramento"
    },
    "user": "Requerente",
    "noFiles": "Sem ficheiros",
    "files": "Ficheiros",
    "call": "Procedimento",
    "subject": "Assunto",
    "logs": "Auditoria",
    "activity": "Atividade",
    "description": "Descrição",
    "title": "Titulo",
    "createdAt": "Criado em",
    "openAt": "Aberto em",
    "closedAt": "Fechado em",
    "messages": "Mensagens",
    "assignees": "Responsáveis",
    "addAssignees": "Adicionar",
    "notDefined": "Não definido",
    "errorOccurred": {
      "title": "Ocorreu um erro",
      "message": "Ocorreu um erro não esperado ao guardar a candidatura."
    },
    "errorOccuredSavingMessage": "Ocorreu um erro não esperado ao guardar a mensagem."
  },
  "en": {
    "receipts": "Receipts",
    "deletedAt": "Deleted at",
    "has_different_address":  "Different address",
    "identityTypes": {
      "cc": "Citizen ID card",
      "passport": "Passport",
      "residencePermit": "Residence permit"
    },
    "studyCycles": {
      "1st": "1º Ciclo - Licenciatura",
      "2nd": "2º Ciclo - Mestrado",
      "1st-2nd": "1º e 2º Ciclo - Mestrado Integrado",
      "3rd": "3º Ciclo - Doutoramento"
    },
    "fileTypes": {
      "household_fiscal_address": "Proff of household fiscal address",
      "family_allowance": "Proff of family allowance",
      "permanent_residence": "Proff of permanent residence",
      "non_existence_of_debts": "Proff of non existence of debts",
      "payment_receipts": "Proff of payment receipts",
      "iban": "Proff of IBAN"
    },
    "user": "User",
    "noFiles": "No files",
    "files": "Files",
    "call": "call",
    "subject": "Subject",
    "logs": "Logs",
    "activity": "Activity",
    "description": "Description",
    "title": "Title",
    "createdAt": "Created at",
    "openAt": "Opened at",
    "closedAt": "Closed at",
    "assignees": "Assignees",
    "messages": "Messages",
    "addAssignees": "Add",
    "notDefined": "Not defined",
    "errorOccurred": {
      "title": "An error has occurred",
      "message": "An unexpected error occurred while saving the application."
    },
    "errorOccuredSavingMessage": "An unexpected error occurred while saving the message."
  }
}
</i18n>
