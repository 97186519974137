<template>
  <div>
    <DashboardHero
      :title="call.title[language] || $t('notDefined')"
      :title-label="call.prefix + '' + call.code"
      :tag="$t(`callType.${call.type}`)"
      class="mt-2 mb-5"
      type="cover"
      background="abstract-02"
    >
      <template #toolbar>
        <div v-if="checks.isOpenToApplications" class="px-3 py-1 rounded-full bg-primary bg-opacity-90">
          <div class="inline-flex flex-shrink-0 text-white relative gap-2">
            <div class="font-semibold">Candidaturas abertas</div>
            <fw-dot color="white" inline class="mt-1" />
          </div>
        </div>
        <div v-else-if="checks.isClosedToApplications && call.state !== 'draft'">
          <div class="text-sm opacity-70 font-medium">
            Candidaturas encerradas
          </div>
        </div>
        <div v-else-if="checks.isOpeningSoon">
          <div class="text-primary font-semibold">Brevemente</div>
        </div>
      </template>

      <template #footer>
        <div>
          <fw-label color="text-white opacity-50">{{ $t('createdAt') }}</fw-label>
          <div>{{ call.created_date | formatDateTime }}</div>
        </div>
        <div v-if="call.state !== 'draft'">
          <fw-label color="text-white opacity-50">{{ $t('publishedAt') }}</fw-label>
          <div>{{ call.published_date | formatDate }}</div>
        </div>
        <div>
          <fw-label color="text-white opacity-50">{{ $t('startsAt') }}</fw-label>
          <div>{{ call.start_date | formatDate }}</div>
        </div>
        <div>
          <fw-label color="text-white opacity-50">{{ $t('endsAt') }}</fw-label>
          <div>{{ call.end_date | formatDate }}</div>
        </div>
      </template>
    </DashboardHero>

    <fw-panel v-if="call.state !== 'draft'" title="Estatísticas" class="my-5">
      <PanelStats :stats="callStatsTotals" :stats-warning="false" />
    </fw-panel>

    <fw-panel :title="$t('statusMainBoardTitle')" class="my-5">
      <div class="flex flex-col gap-4">
        <div class="flex gap-3 text-gray-500">
          <template v-for="(status, key) in callStatus">
            <div
              :key="key"
              :class="{
                'w-2/5 border-primary min-h-28': status.isRunning,
                'w-1/4 border-white min-h-28': !status.isRunning,
                'text-primary': status.isActive,
              }"
              class="relative rounded-xl bg-white border p-4 flex flex-col text-center font-semibold items-center gap-1 group"
            >
              <div class="w-full flex items-center justify-center">
                <div v-if="status.isDone">
                  <fw-icon-check-solid class="w-6 h-6 flex-shrink-0" />
                </div>
                <div v-else-if="status.isRunning">
                  <fw-icon-run class="w-6 h-6 flex-shrink-0 text-primary animate-pulse" />
                </div>
                <div v-else>
                  <fw-icon-rest class="w-6 h-6 flex-shrink-0 text-gray-300" />
                </div>
              </div>
              <div
                class="flex-1"
                :class="{
                  'font-bold': status.isActive || status.isDone,
                }"
              >
                {{ status.label }}
              </div>
              <div class="flex justify-center items-center font-normal text-xs">
                <div v-if="status.isRunning" class="text-primary">
                  {{ $t('phaseOngoing') }}
                </div>
                <div v-else-if="status.isDone && key !== 'closed'">
                  {{ $t('phaseFinished') }}
                </div>
                <div v-else-if="!status.isDone && !status.isActive">
                  {{ $t('phasePending') }}
                </div>
                <div v-else-if="key === 'closed' && status.isDone">
                  {{ $t('phaseClosed') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </fw-panel>

    <fw-panel v-if="users" title="Gestores e operadores" class="my-5" boxed="sm" custom-class="flex gap-5 bg-white">
      <div v-if="users" class="grid grid-cols-3 gap-1 w-full">
        <Person v-for="userManager in users" :key="userManager.key" :no-style="true" :person="userManager" />
      </div>
      <div v-else class="text-sm text-gray-500">
        Ainda não foram associados gestores ou operadores especificamente a este procedimento.
      </div>
    </fw-panel>
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'

export default {
  components: {
    DashboardHero,
    Person,
    PanelStats,
  },

  props: {
    call: {
      type: Object,
      default: () => {},
    },
    checks: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    totalRunningApplications() {
      return this.call && this.call.stats
        ? this.call.stats.complaint +
            this.call.stats.complaint_analysis +
            this.call.stats.in_analysis +
            this.call.stats.in_analysis_waiting +
            this.call.stats.in_progress +
            this.call.stats.submitted
        : 0
    },

    callStatus() {
      if (!this.call.stats) return {}
      return {
        draft: {
          label: this.$t('callStatus.draft'),
          canChange: false,
          isActive: this.call.state == 'draft',
          isRunning: this.call.state == 'draft',
          isDone: this.call.state !== 'draft',
        },
        published: {
          label: this.$t('callStatus.publishedAndOpen'),
          canChange: false,
          isActive: this.call.state === 'published' && this.call.time_to_start < 1 && this.call.time_to_end > 0,
          isRunning: this.call.state === 'published' && this.call.time_to_start < 1 && this.call.time_to_end > 0,
          isDone: this.call.state === 'closed' || this.call.time_to_end < 0,
        },
        running: {
          label: this.$t('callStatus.running'),
          canChange: false,
          isActive: this.call.state === 'published' && this.call.time_to_start < 1,
          isRunning: this.call.state === 'published',
          isDone: this.call.state === 'closed',
        },
        closed: {
          label: this.$t('callStatus.closed'),
          canChange: false,
          isActive: this.call.state == 'closed',
          isRunning: false,
          isDone: this.call.state == 'closed',
        },
      }
    },

    callStatsTotals() {
      if (!this.call.stats) return []
      return [
        {
          label: 'Submetidas',
          value: this.totalRunningApplications,
          valueSuffix: null,
          description: 'Total',
        },
        {
          label: 'Em análise',
          value: this.call.stats.in_analysis + this.call.stats.in_analysis_waiting,
          valueSuffix: null,
          description: 'Aguarda decisão',
        },
        {
          label: 'Aceites',
          value: this.call.stats.in_progress,
          valueSuffix: null,
          description: 'Em progresso',
        },
        {
          label: 'Em audiência',
          value: this.call.stats.complaint + this.call.stats.complaint_analysis,
          valueSuffix: null,
          description: 'Rejeitadas',
        },
      ]
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "statusMainBoardTitle": "Estado",
    "callKey": "Chave do procedimento",
    "createdAt": "Criado em",
    "creator": "Criador",
    "notDefined": "Não definido",
    "title": "Titulo",
    "callType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho"
    },
    "callStatus": {
      "draft": "Rascunho",
      "publishedAndOpen": "Aberto a candidaturas",
      "running": "Análise de candidaturas",
      "closed": "Terminado"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "startsAt": "Inicio de candidaturas",
    "endsAt": "Fim de candidaturas",
    "publishedAt": "Publicado em",
    "people": "Pessoas",
    "submittedApplications": "Submetidas",
    "candidates": "Candidaturas"
  },
  "en": {
    "statusMainBoardTitle": "Estado",
    "callKey": "Chave do procedimento",
    "createdAt": "Criado em",
    "creator": "Criador",
    "notDefined": "Não definido",
    "title": "Titulo",
    "callType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Occupational Medicine"
    },
    "callStatus": {
      "draft": "Rascunho",
      "publishedAndOpen": "Aberto a candidaturas",
      "running": "Análise de candidaturas",
      "closed": "Terminado"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "startsAt": "Inicio de candidaturas",
    "endsAt": "Fim de candidaturas",
    "publishedAt": "Publicado em",
    "people": "Pessoas",
    "submittedApplications": "Submitted",
    "candidates": "Applications"
  }
}
</i18n>
