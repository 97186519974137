<template>
  <PanelActivity
    api-endpoint="getCallActivityLogs"
    :active-filters="activeFilters"
    :inject-payload="{ callKey: callKey }"
  />
</template>

<script>
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'

export default {
  components: {
    PanelActivity,
  },

  props: {
    callKey: {
      type: String,
    },
  },

  data() {
    return {
      activeFilters: {
        call: this.callKey,
      },
    }
  },
}
</script>
